import convert from 'color-convert';
import { hexToRgb } from './ImageEditorFunctions';

export const handleRGBChange = (event, setInputColor, selectedColor, setSelectedColor) => {
  const rgbValues = event.target.value
    .replace(/[^\d,]/g, '') // Remove non-digit and non-comma characters
    .split(',')
    .map((value) => (value.trim() === '' ? NaN : parseInt(value, 10))); // Parse each value as an integer, set NaN if not a valid number

  // Check if all RGB values are valid numbers
  if (rgbValues.every((value) => !isNaN(value))) {
    const updatedColor = convert.rgb.hex(rgbValues[0], rgbValues[1], rgbValues[2]);
    setInputColor(`#${updatedColor}`);

    // Recalculate CIELAB and CMYK values based on the updated RGB values
    const updatedLab = convert.rgb.lab(rgbValues[0], rgbValues[1], rgbValues[2]);
    const updatedCmyk = convert.rgb.cmyk(rgbValues[0], rgbValues[1], rgbValues[2]);

    setSelectedColor({
      ...selectedColor,
      color: { rgb: rgbValues, lab: updatedLab, cmyk: updatedCmyk },
    });
  }
};

export const handleLabChange = (event, setInputColor, selectedColor, setSelectedColor) => {
  const labValues = event.target.value
    .replace(/[^\d,-]/g, '') // Remove non-digit, non-comma, and non-hyphen characters
    .split(',')
    .map((value) => (value.trim() === '' ? NaN : parseFloat(value))); // Parse each value as a floating-point number, set NaN if not a valid number

  // Check if all CIELAB values are valid numbers
  if (labValues.every((value) => !isNaN(value))) {
    const updatedRgb = convert.lab.rgb(labValues[0], labValues[1], labValues[2]);
    const updatedColor = convert.rgb.hex(updatedRgb[0], updatedRgb[1], updatedRgb[2]);
    const updatedCmyk = convert.rgb.cmyk(updatedRgb[0], updatedRgb[1], updatedRgb[2]);

    setInputColor(`#${updatedColor}`);

    setSelectedColor({
      ...selectedColor,
      color: { rgb: updatedRgb, lab: labValues, cmyk: updatedCmyk },
    });
  }
};

export const handleCmykChange = (event, setInputColor, selectedColor, setSelectedColor) => {
  const cmykValues = event.target.value
    .replace(/[^\d,-]/g, '') // Remove non-digit, non-comma, and non-hyphen characters
    .split(',')
    .map((value) => (value.trim() === '' ? NaN : parseFloat(value))); // Parse each value as a floating-point number, set NaN if not a valid number

  // Check if all CMYK values are valid numbers
  if (cmykValues.every((value) => !isNaN(value))) {
    const updatedRgb = convert.cmyk.rgb(cmykValues[0], cmykValues[1], cmykValues[2], cmykValues[3]);
    const updatedColor = convert.rgb.hex(updatedRgb[0], updatedRgb[1], updatedRgb[2]);
    const updatedLab = convert.rgb.lab(updatedRgb[0], updatedRgb[1], updatedRgb[2]);

    setInputColor(`#${updatedColor}`);

    setSelectedColor({
      ...selectedColor,
      color: { rgb: updatedRgb, lab: updatedLab, cmyk: cmykValues },
    });
  }
};

export const handleLoadColor = (inputColor, selectedColor, setSelectedColor, inputColorLuminescenceValues, setShades, shadesRef) => {
  // Function to generate shades based on inputColor and factors provided by the user
  const generateShades = (inputColor, factors) => {
    if (inputColor === '' || factors.length === 0) return [];
    const inputColorRgb = hexToRgb(inputColor);
    let shades = factors.map(factor => {
      let newR, newG, newB;
      if (factor < 0) {
        // For negative factors, generate darker shades
        newR = Math.max(0, Math.round(inputColorRgb.r * (1 + factor)));
        newG = Math.max(0, Math.round(inputColorRgb.g * (1 + factor)));
        newB = Math.max(0, Math.round(inputColorRgb.b * (1 + factor)));
      } else {
        // For positive factors, generate lighter shades
        newR = Math.min(255, Math.round(inputColorRgb.r + (255 - inputColorRgb.r) * factor));
        newG = Math.min(255, Math.round(inputColorRgb.g + (255 - inputColorRgb.g) * factor));
        newB = Math.min(255, Math.round(inputColorRgb.b + (255 - inputColorRgb.b) * factor));
      }
      return `rgb(${newR}, ${newG}, ${newB})`;
    });
    setShades(shades);
    shadesRef.current = shades;
  };
  // Convert luminescence values to the desired format
  const luminescenceValuesArray = inputColorLuminescenceValues.map(value => parseFloat(value)).filter(value => !isNaN(value));
  generateShades(inputColor, luminescenceValuesArray);
  // Convert input color to RGB
  const rgb = convert.hex.rgb(inputColor);
  // Convert RGB to other formats
  const lab = convert.rgb.lab(rgb[0], rgb[1], rgb[2]);
  const cmyk = convert.rgb.cmyk(rgb[0], rgb[1], rgb[2]);

  setSelectedColor({ ...selectedColor, color: { rgb, lab, cmyk } });
};

// Function to convert Lab color to RGB
const labToRgb = (labColor) => {
  // Extract Lab components from the string
  const [L, a, b] = labColor.match(/-?\d+(\.\d+)?/g).map(Number);

  // Reference white point
  const Xn = 95.047;
  const Yn = 100.000;
  const Zn = 108.883;

  // Convert Lab to XYZ
  const fy = (L + 16) / 115;
  const fx = fy + a / 500;
  const fz = fy - b / 200;

  const x = fx > 6 / 29 ? Math.pow(fx, 3) * Xn : (fx - 16 / 116) * 3 * Math.pow(6 / 29, 2) * Xn;
  const y = fy > 6 / 29 ? Math.pow(fy, 3) * Yn : (fy - 16 / 116) * 3 * Math.pow(6 / 29, 2) * Yn;
  const z = fz > 6 / 29 ? Math.pow(fz, 3) * Zn : (fz - 16 / 116) * 3 * Math.pow(6 / 29, 2) * Zn;

  // XYZ to RGB conversion matrix
  const M = [
      [3.2404542, -1.5371385, -0.4985314],
      [-0.9692660, 1.8760108, 0.0415560],
      [0.0556434, -0.2040259, 1.0572252]
  ];

  // Multiply XYZ by conversion matrix
  const [X, Y, Z] = [x, y, z].map((val, i) => val / 100);
  const [R, G, B] = M.map(row => row.map((val, i) => val * [X, Y, Z][i]).reduce((acc, curr) => acc + curr));

  // Convert the linear RGB values to gamma-corrected values
  const linearToGammaCorrected = (c) => c > 0.0031308 ? 1.055 * Math.pow(c, 1 / 2.4) - 0.055 : 12.92 * c;

  // Apply gamma correction and clamp RGB values to the valid range [0, 255]
  const clampAndCorrect = (val) => Math.max(0, Math.min(255, Math.round(linearToGammaCorrected(val) * 255)));

  // Return RGB color string
  return `rgb(${clampAndCorrect(R)},${clampAndCorrect(G)},${clampAndCorrect(B)})`;
};

// Function to convert CMYK to RGB
const cmykToRgb = (c, m, y, k) => {
  const r = 255 * (1 - c / 100) * (1 - k / 100);
  const g = 255 * (1 - m / 100) * (1 - k / 100);
  const b = 255 * (1 - y / 100) * (1 - k / 100);
  return { r, g, b };
};

// Function to determine the active color with appropriate format
export const getSelectedColorOption = (colorObj, type) => {
  const getColorValues = (obj) => {
    const { R, G, B } = obj.bgColorRgb || obj.dotColorRgb || {};
    const { L, A, B: BB } = obj.bgColorCielab || obj.dotColorCielab || {};
    const { C, M, Y, K } = obj.bgColorCmyk || obj.dotColorCmyk || {};
    return { R, G, B, L, A, BB, C, M, Y, K };
  };

  const { R, G, B, L, A, BB, C, M, Y, K } = getColorValues(colorObj);

  if (type === 'bgColor') {
    if (R !== "" && G !== "" && B !== "") {
      return `rgb(${R},${G},${B})`;
    } else if (L !== "" && A !== "" && BB !== "") {
      const rgbColor = labToRgb(`lab(${L},${A},${BB})`)
      return rgbColor;
    } else if (C !== "" && M !== "" && Y !== "" && K !== "") {
      const { r, g, b } = cmykToRgb(C, M, Y, K);
      const cmyktorgb = `rgb(${r},${g},${b})`;
      return cmyktorgb;
    }
  } else {
    if (R !== "" && G !== "" && B !== "") {
      return `rgb(${R},${G},${B})`;
    } else if (L !== "" && A !== "" && BB !== "") {
      const rgbColor = labToRgb(`lab(${L},${A},${BB})`)
      return rgbColor;
    } else if (C !== "" && M !== "" && Y !== "" && K !== "") {
      const { r, g, b } = cmykToRgb(C, M, Y, K);
      const cmyktorgb = `rgb(${Math.floor(r)},${Math.floor(g)},${Math.floor(b)})`;
      return cmyktorgb;
    }
  }
  // Default to white if no valid color found
  return "rgb(255,255,255)";
};