/* eslint-disable no-useless-escape */
import React, { useRef, useState } from 'react'
import PickColor from '../commonUI/PickColor'
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi2'
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa'
import { handleAddDotColor, handleAddInput, handleRemoveDotColor, handleRemoveInput, hexToRgb } from '../../commonfunctions/ImageEditorFunctions'
import { isBgDotColorAtIndexEmpty } from '../../commonfunctions/FormPayloadFunctions'
import { MdDelete } from 'react-icons/md'
import rainbowImage from '../../media/colorpicker.png';
import AsyncSelect from 'react-select/async';
import fontList from '../../styles/fontList.json'
import convert from 'color-convert';
import { getSelectedColorOption } from '../../commonfunctions/ColorPickerFunctions'

const ImageForm = ({ formData, setFormData, formError, showError, bgColorNoticeMessage, dotColorNoticeMessages, openConfirmModal, openUploadUserImageModal, setSectionIndexToRemove, selectedFont, setSelectedFont, isChecked, setChecked, handleSubmit, loadColorUi, formButton }) => {
  const [signAdd, setSignAdd] = useState();
  const [showBgColorNote, setShowBgColorNote] = useState(true);
  const [showDotColorNote, setShowDotColorNote] = useState([]);
  const bgColorInputRef = useRef();
  const bgDotColorInputRef = useRef([]);

  const Shwdot = (sectionIndex) => {
    setSignAdd(signAdd === sectionIndex ? null : sectionIndex);
  }

  const handleToggle = () => {
    setChecked(!isChecked);
    setFormData({ ...formData, lock: !isChecked });
  };

  const handleInputChange = (field, value) => {
    if (field === 'numberToDraw') {
      const isValidInput = /^.{0,2}$/.test(value);
      if (isValidInput) {
        setFormData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
      }
    } else if (field === 'frameShape' || field === 'dotSelectionType') {
      // Accept string value for frameShape without validation
      setFormData({ ...formData, [field]: value });
    } else {
      // Validate if the input is a valid number or float
      const isValidFloat = /^$|^\d*\.?\d*$/.test(value);
      if (isValidFloat) {
        setFormData({ ...formData, [field]: value });
      }
    }
  };

  const handleCustomDotSizesInputChange = (index, value) => {
    // Validate if the input is a valid number or float
    const isValidFloat = /^$|^\d*\.?\d*$/.test(value);
    if (isValidFloat) {
      const updatedDotSizes = [...formData.differentDotSizes];
      updatedDotSizes[index] = value;
      setFormData({ ...formData, differentDotSizes: updatedDotSizes });
    }
  };

  const handleColorInputChange = (e, colorType, component, sectionIndex) => {
    const { value } = e.target;

    if (!isNaN(value) || value === '-' || value === '') {
      if (sectionIndex === undefined) {
        setShowBgColorNote(true)
        setFormData((prevFormData) => ({
          ...prevFormData,
          [colorType]: {
            ...prevFormData[colorType],
            [component]: value,
          },
        }));
      } else {
        setShowDotColorNote(new Array(formData.bgDotColor.length).fill(true));
        setFormData((prevFormData) => {
          const updatedBgDotColor = [...prevFormData.bgDotColor];
          updatedBgDotColor[sectionIndex] = {
            ...updatedBgDotColor[sectionIndex],
            [colorType]: {
              ...updatedBgDotColor[sectionIndex][colorType],
              [component]: value,
            },
          };
          return { ...prevFormData, bgDotColor: updatedBgDotColor };
        });
      }
    }
  };

  const handleLuminescenceChange = (sectionIndex, luminescenceValue, valueIndex) => {
    // Validate if the input is a valid number, float, or negative number
    const isValidValue = /^$|^(\-)?\d*\.?\d*$/.test(luminescenceValue);
    if (isValidValue) {
      setFormData((prevFormData) => {
        const updatedBgDotColor = [...prevFormData.bgDotColor];

        // Update the specific luminescence value for the given section and index
        updatedBgDotColor[sectionIndex].luminescenceValues[valueIndex] = luminescenceValue;

        return {
          ...prevFormData,
          bgDotColor: updatedBgDotColor,
        };
      });
    }
  };

  const handlePercentageInputChange = (sectionIndex, value) => {
    // Validate if the input is a valid number or float
    const isValidFloat = /^$|^\d*\.?\d*$/.test(value);
    if (isValidFloat) {
      // Assuming formData and setFormData are available here
      setFormData((prevData) => {
        const updatedBgDotColor = [...prevData.bgDotColor]; // Copy the array to avoid mutating state directly
        updatedBgDotColor[sectionIndex] = {
          ...updatedBgDotColor[sectionIndex],
          percentage: value,
        };
        return {
          ...prevData,
          bgDotColor: updatedBgDotColor,
        };
      });
    }
  };

  const handleFontChange = (selectedOption) => {
    setSelectedFont({ value: selectedOption?.font, label: selectedOption?.label });
  };

  const filterFonts = (inputValue) => {
    return fontList.filter((font) =>
      font.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterFonts(inputValue));
    }, 1000);
  };

  const handlebgColorChange = (e) => {
    setShowBgColorNote(false)
    let rgb = hexToRgb(e.target.value)
    const cielab = convert.rgb.lab([rgb.r, rgb.g, rgb.b]);
    const cmyk = convert.rgb.cmyk([rgb.r, rgb.g, rgb.b]);
    setFormData((prevData) => ({
      ...prevData,
      bgColorCielab: { L: cielab[0].toString(), A: cielab[1].toString(), B: cielab[2].toString() },
      bgColorRgb: { R: rgb.r.toString(), G: rgb.g.toString(), B: rgb.b.toString() },
      bgColorCmyk: { C: cmyk[0].toString(), M: cmyk[1].toString(), Y: cmyk[2].toString(), K: cmyk[3].toString() }
    }));
  };

  const handlebgDotColorChange = (e, sectionIndex) => {
    const updatedShowDotColorNote = [...showDotColorNote];
    updatedShowDotColorNote[sectionIndex] = false;
    setShowDotColorNote(updatedShowDotColorNote);
    let rgb = hexToRgb(e.target.value)
    const cielab = convert.rgb.lab([rgb.r, rgb.g, rgb.b]);
    const cmyk = convert.rgb.cmyk([rgb.r, rgb.g, rgb.b]);
    setFormData((prevData) => {
      const updatedBgDotColor = [...prevData.bgDotColor]; // Copy the array to avoid mutating state directly
      updatedBgDotColor[sectionIndex] = {
        ...updatedBgDotColor[sectionIndex],
        dotColorCielab: { L: cielab[0].toString(), A: cielab[1].toString(), B: cielab[2].toString() },
        dotColorRgb: { R: rgb.r.toString(), G: rgb.g.toString(), B: rgb.b.toString() },
        dotColorCmyk: { C: cmyk[0].toString(), M: cmyk[1].toString(), Y: cmyk[2].toString(), K: cmyk[3].toString() }
      };
      return {
        ...prevData,
        bgDotColor: updatedBgDotColor,
      };
    });
  };

  return (
    <form className='max-w-screen-lg mx-auto border-b-[1px] border-gray-300 mb-1'>
      <div className='flex flex-col mb-2'>
        <div className='flex justify-between items-center relative'>
          <div className='flex text-xs text-gray-700 mt-2 ml-1 mb-1'>
            <span>BACKGROUND COLOR (HUE)</span>
            <div
                  className="w-4 h-4 ml-1 rounded-full border border-gray-500"
                  style={{ backgroundColor: getSelectedColorOption({bgColorCielab : formData.bgColorCielab,bgColorRgb : formData.bgColorRgb, bgColorCmyk: formData.bgColorCmyk},'bgColor') }}
                ></div>
          </div>
          <PickColor
            inputRef={bgColorInputRef}
            handleColorChange={handlebgColorChange}
          />
        </div>
        <div className='flex flex-col lg:flex-row gap-1 mb-2'>
          <div className="mb-2 lg:border-e-[2px] pr-2 border-gray-300">
            <label className="block text-gray-400 text-xs ml-1 mb-1">CIELAB (L40)</label>
            <div className='w-full 2xl:max-w-fit h-[30px] text-xs px-1  rounded-md '>
              <span className='flex mt-[5px]  h-[30px] border rounded bg-white'>
                <input type='text' name='L'
                  value={formData.bgColorCielab.L}
                  onChange={(e) => handleColorInputChange(e, 'bgColorCielab', 'L')}
                  className='px-1 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-sm text-gray-400'>,</span>
                <input type='text' name='A'
                  value={formData.bgColorCielab.A}
                  onChange={(e) => handleColorInputChange(e, 'bgColorCielab', 'A')}
                  className='px-1 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-sm text-gray-400'>,</span>
                <input type='text' name='B'
                  value={formData.bgColorCielab.B}
                  onChange={(e) => handleColorInputChange(e, 'bgColorCielab', 'B')}
                  className='px-1 w-8 ml-1 mt-[-1px] mr-1 bg-transparent border-none myInput ' />
              </span>
            </div>
          </div>
          <div className="mb-2 lg:border-e-[2px] pr-2 border-gray-300">
            <label className="block text-gray-400 text-xs ml-1 mb-1">RGB (digital)</label>
            <div className='w-full 2xl:max-w-fit h-[30px] text-xs px-1 rounded-md'>
              <span className='flex mt-[5px]  h-[30px] border rounded bg-white'>
                <input type='text' name='R'
                  value={formData.bgColorRgb.R}
                  onChange={(e) => handleColorInputChange(e, 'bgColorRgb', 'R')}
                  className='px-1 w-8 ml-0.5 mt-[-1px]bg-transparent border-none myInput' /><span className='text-sm text-gray-400'>,</span>
                <input type='text' name='G'
                  value={formData.bgColorRgb.G}
                  onChange={(e) => handleColorInputChange(e, 'bgColorRgb', 'G')}
                  className='px-1 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput' /><span className='text-sm text-gray-400'>,</span>
                <input type='text' name='B'
                  value={formData.bgColorRgb.B}
                  onChange={(e) => handleColorInputChange(e, 'bgColorRgb', 'B')}
                  className='px-1 w-8 ml-1 mt-[-1px] mr-0.5 bg-transparent border-none myInput' />
              </span>
            </div>
          </div>
          <div className="mb-2">
            <label className="block text-gray-400 text-xs ml-1 mb-1">CMYK (print)</label>
            <div className='w-full 2xl:max-w-fit h-[30px] text-xs px-1  rounded-md '>
              <span className='flex mt-[5px]  h-[30px] border rounded bg-white pe-2'>
                <input type='text' name='C'
                  value={formData.bgColorCmyk.C}
                  onChange={(e) => handleColorInputChange(e, 'bgColorCmyk', 'C')}
                  className='px-1 py-0.5 w-8 ml-0.5 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-xs mt-1.5 text-gray-400'>%,</span>
                <input type='text' name='M'
                  value={formData.bgColorCmyk.M}
                  onChange={(e) => handleColorInputChange(e, 'bgColorCmyk', 'M')}
                  className='px-1 py-0.5 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-xs mt-1.5 text-gray-400'>%,</span>
                <input type='text' name='Y'
                  value={formData.bgColorCmyk.Y}
                  onChange={(e) => handleColorInputChange(e, 'bgColorCmyk', 'Y')}
                  className='px-1 py-0.5 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-xs mt-1.5 text-gray-400'>%,</span>
                <input type='text' name='K'
                  value={formData.bgColorCmyk.K}
                  onChange={(e) => handleColorInputChange(e, 'bgColorCmyk', 'K')}
                  className='px-1 py-0.5 w-8 ml-1 mt-[-1px] mr-0.5 bg-transparent border-none myInput ' />
                <span className='text-xs mt-1.5 text-gray-400'>%</span></span>
            </div>
          </div>
        </div>
        {showError && formError?.bgColor ? (
          <span className='mt-[-8px] mb-2 text-xs text-red-600'>{`${formError?.bgColor}*`}</span>
        ) : (
          (formError?.bgColor && (
            Object.values(formData.bgColorCielab).some(value => value) ||
            Object.values(formData.bgColorRgb).some(value => value) ||
            Object.values(formData.bgColorCmyk).some(value => value)
          )) && (
            <span className='mt-[-8px] mb-2 text-xs text-red-600'>{`${formError?.bgColor}*`}</span>
          )
        )}
        {showBgColorNote && bgColorNoticeMessage !== '' && <span className='mt-[-8px] mb-1 text-xs text-yellow-600'>{`${bgColorNoticeMessage}*`}</span>}
        <div className="border-b-[1px] border-gray-300 mb-2"></div>
      </div>
      <div className='relative'>
        <div className='flex gap-1 flex-wrap absolute right-1'></div>
        {formData.bgDotColor.map((dotColorSection, sectionIndex) => (
          <>
            <div className='flex w-full justify-between items-center'>
              <h2 className='text-sm font-bold flex'>
                <span>Color Option {sectionIndex + 1}</span>
                <div
                  className="w-4 h-4 ml-1 mt-[1.5px] rounded-full border border-gray-500"
                  style={{ backgroundColor: getSelectedColorOption(dotColorSection,'bgDotColor') }}
                ></div>
              </h2>
              <div className='flex items-center gap-2'>
                {sectionIndex === formData.bgDotColor.length - 1 && (
                  <button type='button' className="shadow-md bg-blue-100 m-1 ml-0 h-6 border border-gray-300 cursor-pointer rounded hover:bg-blue-300" onClick={() => handleAddDotColor(formData, setFormData)}>
                    <FaPlus className='w-3 h-3 ml-[7px] mr-[7px] text-gray-700' />
                  </button>
                )}
                {sectionIndex === 0 ? "" : <button type='button' className="shadow-md bg-blue-100 m-1 ml-0 h-6 border border-gray-300 cursor-pointer rounded hover:bg-blue-300" onClick={() => {
                  isBgDotColorAtIndexEmpty(dotColorSection) ? handleRemoveDotColor(formData, setFormData, sectionIndex) : openConfirmModal()
                  setSectionIndexToRemove(sectionIndex)
                }}
                >
                  <MdDelete className='w-3 h-3 ml-[7px] mr-[7px] text-gray-700' /></button>}
                {sectionIndex === 0 ? "" : <div onClick={() => Shwdot(sectionIndex)}>{signAdd === sectionIndex ? <div className='text-xs'><FaChevronUp /></div> : <div className='text-xs'><FaChevronDown /></div>}</div>}
              </div>
            </div>

            <div key={sectionIndex} className={sectionIndex === 0 ? "flex flex-col mb-2" : signAdd === sectionIndex ? 'block' : 'hidden'}>
              <div className='flex justify-between items-center relative'>
                <div className='text-xs text-gray-700 ml-1 mb-1'>BACKGROUND DOT COLOR (HUE)</div>
                <div className='flex items-center'>
                  <span className='text-xs text-gray-700 pr-6 mt-[4px]'>Color Picker</span>
                  <div className='pl-0.5 pt-0.5 bg-white absolute top-[1px] right-0 bottom-0 rounded-md w-5 h-5 flex items-center'>
                    <input type="color"
                      ref={(ref) => bgDotColorInputRef.current[sectionIndex] = ref}
                      onChange={(e) => {
                        handlebgDotColorChange(e, sectionIndex)
                      }}
                      className="appearance-none ml-4 w-2 h-2 rounded-md border-none outline-none cursor-pointer" />
                    <div
                      className='absolute top-[3px] right-0 bottom-0 left-[2px] rounded-md cursor-pointer bg-cover bg-center w-4 h-4'
                      style={{ backgroundImage: `url(${rainbowImage})` }}
                      onClick={() => bgDotColorInputRef.current[sectionIndex].click()}
                    ></div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col lg:flex-row gap-1'>
                <div className="mb-2 lg:border-e-[2px] pr-2 border-gray-300">
                  <label className="block text-gray-400 text-xs ml-1 mb-1">CIELAB (L40)</label>
                  <div className='w-full 2xl:max-w-fit h-[30px] text-xs px-1  rounded-md '>
                    <span className='flex mt-[5px]  h-[30px] border rounded bg-white'>

                      <input type='text' name='L'
                        value={formData.bgDotColor[sectionIndex].dotColorCielab.L}
                        onChange={(e) => handleColorInputChange(e, 'dotColorCielab', 'L', sectionIndex)}
                        className='px-1 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-sm text-gray-400'>,</span>
                      <input type='text' name='A'
                        value={formData.bgDotColor[sectionIndex].dotColorCielab.A}
                        onChange={(e) => handleColorInputChange(e, 'dotColorCielab', 'A', sectionIndex)}
                        className='px-1 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-sm text-gray-400'>,</span>
                      <input type='text' name='B'
                        value={formData.bgDotColor[sectionIndex].dotColorCielab.B}
                        onChange={(e) => handleColorInputChange(e, 'dotColorCielab', 'B', sectionIndex)}
                        className='px-1 w-8 ml-1 mt-[-1px] mr-1 bg-transparent border-none myInput ' />
                    </span>
                  </div>
                </div>
                <div className="mb-2 lg:border-e-[2px] pr-2 border-gray-300">
                  <label className="block text-gray-400 text-xs ml-1 mb-1">RGB (digital)</label>
                  <div className='w-full 2xl:max-w-fit h-[30px] text-xs px-1  rounded-md '>
                    <span className='flex mt-[5px]  h-[30px] border rounded bg-white'>

                      <input type='text' name='R'
                        value={formData.bgDotColor[sectionIndex].dotColorRgb.R}
                        onChange={(e) => handleColorInputChange(e, 'dotColorRgb', 'R', sectionIndex)}
                        className='px-1 w-8 ml-0.5 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-sm text-gray-400'>,</span>
                      <input type='text' name='G'
                        value={formData.bgDotColor[sectionIndex].dotColorRgb.G}
                        onChange={(e) => handleColorInputChange(e, 'dotColorRgb', 'G', sectionIndex)}
                        className='px-1 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-sm text-gray-400'>,</span>
                      <input type='text' name='B'
                        value={formData.bgDotColor[sectionIndex].dotColorRgb.B}
                        onChange={(e) => handleColorInputChange(e, 'dotColorRgb', 'B', sectionIndex)}
                        className='px-1 w-8 ml-1 mt-[-1px] mr-0.5 bg-transparent border-none myInput ' />
                    </span>
                  </div>
                </div>
                <div className="mb-2">
                  <label className="block text-gray-400 text-xs ml-1 mb-1">CMYK (print)</label>
                  <div className='w-full 2xl:max-w-fit h-[30px] text-xs px-1  rounded-md '>
                    <span className='flex mt-[5px]  h-[30px] border rounded bg-white pe-2'>
                      <input type='text' name='C'
                        value={formData.bgDotColor[sectionIndex].dotColorCmyk.C}
                        onChange={(e) => handleColorInputChange(e, 'dotColorCmyk', 'C', sectionIndex)}
                        className='px-1 py-0.5 w-8 ml-0.5 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-xs mt-1.5 text-gray-400'>%,</span>
                      <input type='text' name='M'
                        value={formData.bgDotColor[sectionIndex].dotColorCmyk.M}
                        onChange={(e) => handleColorInputChange(e, 'dotColorCmyk', 'M', sectionIndex)}
                        className='px-1 py-0.5 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-xs mt-1.5 text-gray-400'>%,</span>
                      <input type='text' name='Y'
                        value={formData.bgDotColor[sectionIndex].dotColorCmyk.Y}
                        onChange={(e) => handleColorInputChange(e, 'dotColorCmyk', 'Y', sectionIndex)}
                        className='px-1 py-0.5 w-8 ml-1 mt-[-1px] bg-transparent border-none myInput ' /><span className='text-xs mt-1.5 text-gray-400'>%,</span>
                      <input type='text' name='K'
                        value={formData.bgDotColor[sectionIndex].dotColorCmyk.K}
                        onChange={(e) => handleColorInputChange(e, 'dotColorCmyk', 'K', sectionIndex)}
                        className='px-1 py-0.5 w-8 ml-1 mt-[-1px] mr-0.5 bg-transparent border-none myInput ' />
                      <span className='text-xs mt-1.5 text-gray-400'>%</span></span>
                  </div>
                </div>
              </div>
              {showError && formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.dotColorValidation ? (
                <div className='mt-[-4px] mb-[-1px] text-xs text-red-600'>
                  {`${formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.dotColorValidation}*`}
                </div>
              ) : formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.dotColorValidation && (
                (
                  Object.values(formData.bgDotColor[sectionIndex].dotColorCielab).some(value => value) ||
                  Object.values(formData.bgDotColor[sectionIndex].dotColorRgb).some(value => value) ||
                  Object.values(formData.bgDotColor[sectionIndex].dotColorCmyk).some(value => value)
                ) &&
                <div className='mt-[-4px] mb-[-1px] text-xs text-red-600'>
                  {`${formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.dotColorValidation}*`}
                </div>
              )}
              {showDotColorNote[sectionIndex] && dotColorNoticeMessages[sectionIndex] && <span key={sectionIndex} className='mt-[-8px]mb-1 text-xs text-yellow-600'>{`${dotColorNoticeMessages[sectionIndex]}*`}</span>}
              <div className='flex'>
                <div>
                  <div className="text-xs text-gray-700 ml-1 mb-1 mt-1">LUMINESCENCE VALUES</div>
                  <div className="flex gap-1 flex-wrap">
                    {dotColorSection.luminescenceValues.map((value, index) => (
                      <div key={index} className="mb-2">
                        <input type="text" id={`luminescenceInput${index}`}
                          value={formData.bgDotColor[sectionIndex].luminescenceValues[index]}
                          onChange={(e) => handleLuminescenceChange(sectionIndex, e.target.value, index)}
                          className="w-[60px] md:w-[50px] h-[30px] text-xs px-3 py-2 border rounded-md" />
                      </div>))}
                  </div>
                  {showError && formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.luminescenceValuesValidation ? (
                    <div className='mt-[-5px] text-xs text-red-600'>{`${formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.luminescenceValuesValidation}*`}</div>
                  ) : formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.luminescenceValuesValidation && (
                    formData.bgDotColor[sectionIndex].luminescenceValues.some(value => value.trim() !== '') &&
                    <div className='mt-[-5px] text-xs text-red-600'>
                      {`${formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.luminescenceValuesValidation}*`}
                    </div>
                  )}
                </div>
                <div className='ml-10'>
                  <div className="text-xs text-gray-700 ml-1 mb-1 mt-1">Percentage</div>
                  <div className='flex'>
                    <input type="text"
                      className="w-[100px] md:w-[70px] lg:w-12 xl:w-16 2xl:w-[100px] h-[30px] text-xs px-3 py-2 border rounded-md"
                      value={formData.bgDotColor[sectionIndex].percentage}
                      onChange={(e) => handlePercentageInputChange(sectionIndex, e.target.value)}
                    />
                    <span className='text-gray-600 text-xs ml-1 sm:me-2 flex items-center'>%</span>
                  </div>
                  {showError && formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.percentage ? (
                    <div className='mt-1 text-xs text-red-600'>
                      {`${formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.percentage}*`}
                    </div>
                  ) : formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.percentage && (
                    formData.bgDotColor[sectionIndex].percentage !== '' &&
                    <div className='mt-1 text-xs text-red-600'>
                      {`${formError?.bgDotColorValidation[`bgDotColor${sectionIndex}`]?.percentage}*`}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-b-[1px] border-gray-300 mb-2 mt-2"></div>
          </>
        ))}
      </div>
      <div className="text-xs text-gray-700 ml-1 mb-1">NUMBER OF DIFFERENT DOT SIZES</div>
      <div className='flex flex-col sm:flex-row gap-1'>
        <div className='lg:w-70 sm:me-4'>
          {formData.dotSelectionType === 'dotrange' ?
            <>
              <div className='flex gap-3'>
                <div className="mb-2">
                  <label className="block text-gray-400 text-xs ml-1 mb-1" >Minimum</label>
                  <input type="text" id="differentDotSizeMinInput" value={formData.differentDotSizeMin}
                    onChange={(e) => handleInputChange('differentDotSizeMin', e.target.value)}
                    className="w-28 sm:w-14 xl:w-20 2xl:w-20 h-[30px] text-xs px-3 py-2 border rounded-md" />
                  <span className='text-gray-600 text-xs ml-1'>px</span>
                </div>
                <div className="mb-2 lg:ml-8">
                  <label className="block text-gray-400 text-xs ml-1 mb-1">Maximum</label>
                  <input type="text" id="differentDotSizeMaxInput" value={formData.differentDotSizeMax}
                    onChange={(e) => handleInputChange('differentDotSizeMax', e.target.value)}
                    className="w-28 sm:w-14 xl:w-20 2xl:w-20 h-[30px] text-xs px-3 py-2 border rounded-md" />
                  <span className='text-gray-600 text-xs ml-1'>px</span>
                </div>

              </div>
              {showError && (formError?.validations?.differentDotSizeMin || formError?.validations?.differentDotSizeMax) && (
                // Show error message if showError is true and either differentDotSizeMin or differentDotSizeMax has an error
                <div className='mt-[-2px] text-xs text-red-600'>
                  {`${formError?.validations?.differentDotSizeMin && formError?.validations?.differentDotSizeMax ? 'Minimum/Maximum dot size is required*' : ''}`}
                  {`${formError?.validations?.differentDotSizeMin && !formError?.validations?.differentDotSizeMax ? formError.validations.differentDotSizeMin + '*' : ''}`}
                  {`${formError?.validations?.differentDotSizeMin && formError?.validations?.differentDotSizeMax ? '' : formError?.validations?.differentDotSizeMax ? formError.validations.differentDotSizeMax + '*' : ''}`}
                </div>
              )}

              {!showError && (formError?.validations?.differentDotSizeMin || formError?.validations?.differentDotSizeMax) &&
                (formData.differentDotSizeMin !== '' || formData.differentDotSizeMax !== '') && (
                  // Show error message if either differentDotSizeMin or differentDotSizeMax has an error and both fields are not empty
                  <div className='mt-[-2px] text-xs text-red-600'>
                    {`${formError?.validations?.differentDotSizeMin && formError?.validations?.differentDotSizeMax ? 'Minimum/Maximum dot size is required*' : ''}`}
                    {`${formError?.validations?.differentDotSizeMin && !formError?.validations?.differentDotSizeMax ? formError.validations.differentDotSizeMin + '*' : ''}`}
                    {`${formError?.validations?.differentDotSizeMin && formError?.validations?.differentDotSizeMax ? '' : formError?.validations?.differentDotSizeMax ? formError.validations.differentDotSizeMax + '*' : ''}`}
                  </div>
                )}
            </>
            :
            <div className='ml-0'>
              <label className="block text-gray-400 text-xs ml-1 mb-1">Custom dot sizes</label>
              <div className='flex gap-2 overflow-x-auto flex-wrap'>
                {formData.differentDotSizes.map((size, index) => (
                  <div key={index} className="mb-2">
                    <input type="text" id={`customDotSizesInput${index}`} value={size}
                      className="w-16 h-[30px] text-xs px-3 py-2 border rounded-md"
                      onChange={(e) => handleCustomDotSizesInputChange(index, e.target.value)} />
                  </div>))}
                <button type='button' className="shadow-md bg-blue-100 m-1 ml-0 w-9 h-6 border border-gray-300 cursor-pointer rounded hover:bg-blue-300" onClick={() => handleAddInput(setFormData)}>
                  <HiOutlinePlus className='w-3 h-3 ml-[11px] mr-[11px] text-gray-700' />
                </button>
                <button type='button' className="shadow-md bg-blue-100 m-1 ml-[-7px] w-9 h-6 border border-gray-300 cursor-pointer rounded hover:bg-blue-300" onClick={() => handleRemoveInput(1, setFormData)}>
                  <HiOutlineMinus className='w-3 h-3 ml-[11px] mr-[11px] text-gray-700' />
                </button>
              </div>
              {showError && formError?.validations?.differentDotSizes ? (
                <div className='mt-[-5px] text-xs text-red-600'>{`${formError?.validations?.differentDotSizes}*`}</div>
              ) : formError?.validations?.differentDotSizes ? <div className='mt-[-5px] text-xs text-red-600'>{`${formError?.validations?.differentDotSizes}*`}</div> : null}
            </div>}
        </div>
        <div className="ml-0 mt-5">
          <select id="dotSelectionTypeSelect" value={formData.dotSelectionType}
            onChange={(e) => handleInputChange('dotSelectionType', e.target.value)}
            className="w-30 sm:w-[130px] md:w-30 lg:w-30 xl:w-30 2xl:w-30 h-[32px] px-3 py-2 border rounded-md text-xs"
          >
            <option value="dotrange">Dot Size Range</option>
            <option value="dotsizes">Custom Dot Sizes</option>
          </select>
        </div>
      </div>

      <div className="border-b-[1px] border-gray-300 mb-2 mt-3"></div>
      <div className='flex flex-col sm:flex-row gap-1'>
        <div>
          <div className="text-xs text-gray-700 ml-1 mb-1">DISTANCE BETWEEN DOTS</div>
          <div className='lg:w-70 sm:me-4 flex gap-3'>
            <div className="mb-2">
              <label className="block text-gray-400 text-xs ml-1 mb-1" >Minimum</label>
              <input type="text" id="distanceBetweenDotsMinInput" value={formData.distanceBetweenDotsMin}
                onChange={(e) => handleInputChange('distanceBetweenDotsMin', e.target.value)}
                className="w-28 sm:w-14 xl:w-20 2xl:w-20 h-[30px] text-xs px-3 py-2 border rounded-md" />
              <span className='text-gray-600 text-xs ml-1'>px</span>
            </div>
            <div className="mb-2 lg:ml-10">
              <label className="block text-gray-400 text-xs ml-1 mb-1" >Maximum</label>
              <input type="text" id="distanceBetweenDotsMaxInput" value={formData.distanceBetweenDotsMax}
                onChange={(e) => handleInputChange('distanceBetweenDotsMax', e.target.value)}
                className="w-28 sm:w-14 xl:w-20 2xl:w-20 h-[30px] text-xs px-3 py-2 border rounded-md" />
              <span className='text-gray-600 text-xs ml-1'>px</span>
            </div>
          </div>
          {showError && (formError?.validations?.distanceBetweenDotsMin || formError?.validations?.distanceBetweenDotsMax) && (
            // Show error message if showError is true and either distanceBetweenDotsMin or distanceBetweenDotsMax has an error
            <div className='mt-[-2px] text-xs text-red-600'>
              {`${formError?.validations?.distanceBetweenDotsMin && formError?.validations?.distanceBetweenDotsMax ? 'Minimum/Maximum distance is required*' : ''}`}
              {`${formError?.validations?.distanceBetweenDotsMin && !formError?.validations?.distanceBetweenDotsMax ? formError.validations.distanceBetweenDotsMin + '*' : ''}`}
              {`${formError?.validations?.distanceBetweenDotsMin && formError?.validations?.distanceBetweenDotsMax ? '' : formError?.validations?.distanceBetweenDotsMax ? formError.validations.distanceBetweenDotsMax + '*' : ''}`}
            </div>
          )}

          {!showError && (formError?.validations?.distanceBetweenDotsMin || formError?.validations?.distanceBetweenDotsMax) &&
            (formData.distanceBetweenDotsMin !== '' || formData.distanceBetweenDotsMax !== '') && (
              // Show error message if either distanceBetweenDotsMin or distanceBetweenDotsMax has an error and both fields are not empty
              <div className='mt-[-2px] text-xs text-red-600'>
                {`${formError?.validations?.distanceBetweenDotsMin && formError?.validations?.distanceBetweenDotsMax ? 'Minimum/Maximum distance is required*' : ''}`}
                {`${formError?.validations?.distanceBetweenDotsMin && !formError?.validations?.distanceBetweenDotsMax ? formError.validations.distanceBetweenDotsMin + '*' : ''}`}
                {`${formError?.validations?.distanceBetweenDotsMin && formError?.validations?.distanceBetweenDotsMax ? '' : formError?.validations?.distanceBetweenDotsMax ? formError.validations.distanceBetweenDotsMax + '*' : ''}`}
              </div>
            )}
        </div>
        <div>
          <div>
            <div className="text-xs text-gray-700 ml-1">FRAME SHAPE</div>
            <div className="mb-2 mt-6">
              <select id="frameShapeSelect" value={formData.frameShape}
                className="w-30 sm:w-[130px] md:w-30 lg:w-30 xl:w-30 2xl:w-30 h-[32px] px-3 py-2 border rounded-md text-xs"
                onChange={(e) => handleInputChange('frameShape', e.target.value)}>
                <option value="circle">Circle</option>
                <option value="rectangle">Rectangle</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b-[1px] border-gray-300 mb-2 mt-3"></div>
      <div className='flex md:flex-col xl:flex-row'>
        <div>
          <div className="text-xs text-gray-700 ml-1 mb-1">SHAPE SIZE (DIAMETER,HEIGHT,WIDTH)</div>
          <div className='flex gap-3'>
            <div className="mb-2">
              <label className="block text-gray-400 text-xs ml-1 mb-1" >Diameter / Height</label>
              <input type="text" id="shapeSizeDiameterOrHeightInput" value={formData.shapeSizeDiameterOrHeight}
                onChange={(e) => handleInputChange('shapeSizeDiameterOrHeight', e.target.value)}
                className="w-28 h-[30px] text-xs px-3 py-2 border rounded-md" />
              <span className='text-gray-600 text-xs ml-1'>px</span>
            </div>
            <div className="mb-2 lg:ml-4">
              <label className="block text-gray-400 text-xs ml-1 mb-1" >Width</label>
              <input type="text" id="shapeSizeWidthInput" value={formData.shapeSizeWidth}
                onChange={(e) => handleInputChange('shapeSizeWidth', e.target.value)}
                className="w-28 h-[30px] text-xs px-3 py-2 border rounded-md" />
              <span className='text-gray-600 text-xs ml-1'>px</span>
            </div>
          </div>
          {showError && (formError?.validations?.shapeSizeDiameterOrHeight || formError?.validations?.shapeSizeWidth) && (
            // Show error message if showError is true and either shapeSizeDiameterOrHeight or shapeSizeWidth has an error
            <div className='mt-[-2px] text-xs text-red-600'>
              {`${formError?.validations?.shapeSizeDiameterOrHeight && formError?.validations?.shapeSizeWidth ? 'Height/width is required*' : ''}`}
              {`${formError?.validations?.shapeSizeDiameterOrHeight && !formError?.validations?.shapeSizeWidth ? formError.validations.shapeSizeDiameterOrHeight + '*' : ''}`}
              {`${formError?.validations?.shapeSizeDiameterOrHeight && formError?.validations?.shapeSizeWidth ? '' : formError?.validations?.shapeSizeWidth ? formError.validations.shapeSizeWidth + '*' : ''}`}
            </div>
          )}

          {!showError && (formError?.validations?.shapeSizeDiameterOrHeight || formError?.validations?.shapeSizeWidth) &&
            (formData.shapeSizeDiameterOrHeight !== '' || formData.shapeSizeWidth !== '') && (
              // Show error message if either shapeSizeDiameterOrHeight or shapeSizeWidth has an error and both fields are not empty
              <div className='mt-[-2px] text-xs text-red-600'>
                {`${formError?.validations?.shapeSizeDiameterOrHeight && formError?.validations?.shapeSizeWidth ? 'Height/width is required*' : ''}`}
                {`${formError?.validations?.shapeSizeDiameterOrHeight && !formError?.validations?.shapeSizeWidth ? formError.validations.shapeSizeDiameterOrHeight + '*' : ''}`}
                {`${formError?.validations?.shapeSizeDiameterOrHeight && formError?.validations?.shapeSizeWidth ? '' : formError?.validations?.shapeSizeWidth ? formError.validations.shapeSizeWidth + '*' : ''}`}
              </div>
            )}
        </div>
      </div>
      <div className='flex mt-[15px] gap-3'>
        <div className='mr-2 md:mr-4 lg:mr-0'>
          <div className="text-xs text-gray-700 ml-1 mb-1">ENTER KEYWORD</div>
          <div className="mb-2">
            <label className="block text-gray-400 text-xs ml-1 mb-1" >Numbers / Alphabets / Symbols</label>
            <input type="text" id="numberInput" value={formData.numberToDraw}
              onChange={(e) => handleInputChange('numberToDraw', e.target.value)}
              className="w-28 h-[30px] text-xs px-3 py-2 border rounded-md" />
          </div>
        </div>
        <div className='ml-2 md:ml-0 lg:ml-8'>
          <div className="text-xs text-gray-700 ml-1 mb-1">SELECT FONT</div>
          <div className="mb-2 md:mt-1 ">
            <AsyncSelect
              defaultValue={{ value: selectedFont.value, label: selectedFont.label }}
              value={selectedFont}
              onChange={handleFontChange}
              cacheOptions
              defaultOptions
              loadOptions={loadOptions}
              placeholder="Select a font"
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  fontFamily: state.data.font,
                  fontSize: '12px',
                  height: '30px'
                }),
              }}
              className="w-150px md:w-[190px] h-[30px] rounded-md text-xs"
              menuPlacement="auto"
              menuPortalTarget={document.body}
              menuPosition="fixed"
              menuShouldScrollIntoView={false}
              maxMenuHeight={130}
            />
          </div>
        </div>
      </div>
      <div className="border-b-[1px] border-gray-300 mb-2 mt-3"></div>
      <div className='flex flex-col xl:flex-row gap-4 pb-3'>
        <div className='flex gap-2 hidden'>
          <div className='text-xs text-gray-700 ml-1 mb-1 mt-[9px]'>LOCK</div>
          <label className="flex items-center cursor-pointer">
            <div className={`relative w-12 h-6 ${isChecked ? 'bg-gray-400' : 'bg-gray-400'} rounded-full`}>
              <input type="checkbox" className="hidden" checked={isChecked} onChange={handleToggle} />
              <div
                className={`toggle__dot absolute w-6 h-6 bg-white rounded-full shadow ${isChecked ? 'transform translate-x-full' : 'transform translate-x-0'
                  } transition-transform`}
              ></div>
            </div>
            <div className={`ml-3 text-gray-400 font-medium ${isChecked ? 'text-gray-400' : 'text-gray-400'}`}>
              {isChecked ? 'ON' : 'OFF'}
            </div>
          </label>
        </div>
        <button type="button" onClick={handleSubmit}
          className={`shadow-md w-full ${formButton === 'EDIT IMAGE' && 'xl:w-[300px]'} h-[35px] bg-blue-100 text-sm font-semibold xl:text-sm text-gray-700 px-2 py-1 rounded-md hover:bg-blue-300`}
        >{formButton}</button>
        {loadColorUi && <button type="button"
          className={`shadow-md w-full ${formButton === 'EDIT IMAGE' && 'xl:w-[300px]'} h-[35px] bg-blue-100 text-sm font-semibold xl:text-sm text-gray-700 px-2 py-1 rounded-md hover:bg-blue-300`}
          onClick={openUploadUserImageModal}
        >USE USER IMAGE</button>}
      </div>
    </form>
  )
}
export default ImageForm