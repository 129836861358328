import React from 'react';
import '../App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Header from '../components/layout/Header';
import Login from '../components/auth/Login'
import Dashboard from '../components/dashboard/Dashboard';
import ImageContainer from '../components/dashboard/ImageContainer';
import VideoContainer from '../components/dashboard/VideoContainer';
import ImageGenerator from '../components/image/ImageGenerator';
import ImageEditor from '../components/image/ImageEditor';
import ErrorPage from '../components/commonUI/ErrorPage';
import ProtectedRoute from '../components/commonUI/ProtectedRoute';
import ForgetPassword from '../components/auth/ForgetPassword';
import MailFailed from '../components/commonUI/MailFailed';

const router = createBrowserRouter([
    {
        index:true,
        path:"/", 
        element: <Login />,
        errorElement: <ErrorPage />,
    },
    {
        index:true,
        path:"/password/reset/:token", 
        element: <ForgetPassword />,
    },
    {
        index:true,
        path:"/forget_password_session_expired", 
        element: <MailFailed />,
    },
    {
        path: "",
        element: <Header />,
        id:'root',
        children:[
            {
                path:"/dashboard",
                element: <ProtectedRoute><Dashboard /></ProtectedRoute>
            },
            {
                path:"/imagecontainer",
                element: <ProtectedRoute><ImageContainer /></ProtectedRoute>
            },
            {
                path:"/videocontainer",
                element: <ProtectedRoute><VideoContainer /></ProtectedRoute>
            },
            {
                path:"/imagegenerator",
                element: <ProtectedRoute><ImageGenerator /></ProtectedRoute>
            },
            {
                path:"/imageeditor",
                element: <ProtectedRoute><ImageEditor /></ProtectedRoute>
            }
        ]
    }
])

const routesArray = () => {
  return (
    <>
       <RouterProvider router={router} />
    </>
  );
}

export default routesArray;
