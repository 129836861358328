import React from 'react'

const VideoContainer = () => {
  return (
    <>
    <div></div>
    </>
  )
}

export default VideoContainer