import { parse } from 'papaparse';
//upload parameter file
export const handleParameterFileChange = (e, setSelectedParameterFile) => {
  const file = e.target.files[0];
  setSelectedParameterFile(file);
};

export const handleParameterUpload = (selectedParameterFile, formData, setFormData, closeUploadParameterModal,setParameterUploadError) => {
  if (selectedParameterFile) {
     // Check if the selected file is a CSV file
     const acceptedFileTypes = ['csv'];
     const acceptedExtensions = acceptedFileTypes.map((type) => `.${type}`);
     const fileExtension = selectedParameterFile.name.split('.').pop().toLowerCase();
     if (!acceptedExtensions.includes(`.${fileExtension}`)) {
       setParameterUploadError('Please select .csv file.');
       return;
     }
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      let isValid = validateCSV(csv)
      if (!isValid) {
        setParameterUploadError('Format is different, please select correct file.')
        return;
      }
      setParameterUploadError('')
      parse(csv, {
        complete: (result) => {
          const updatedFormData = { ...formData };
          updatedFormData.bgDotColor = []
          updatedFormData.differentDotSizes = []
          result.data.forEach((item, index) => {
            if (index === 0) {
              if (item["Background Color"]) {
                const bgColorModel = getColorModel(item["Background Color"]);
                if (bgColorModel === "Lab") {
                  updatedFormData.bgColorCielab = parseLabColor(item["Background Color"]);
                  updatedFormData.bgColorRgb = { R: '', G: '', B: '' };
                  updatedFormData.bgColorCmyk = { C: '', M: '', Y: '', K: '' };
                } else if (bgColorModel === "RGB") {
                  updatedFormData.bgColorRgb = parseRgbColor(item["Background Color"]);
                  updatedFormData.bgColorCielab = { L: '', A: '', B: '' };
                  updatedFormData.bgColorCmyk = { C: '', M: '', Y: '', K: '' };
                } else if (bgColorModel === "CMYK") {
                  updatedFormData.bgColorCmyk = parseCmykColor(item["Background Color"]);
                  updatedFormData.bgColorCielab = { L: '', A: '', B: '' };
                  updatedFormData.bgColorRgb = { R: '', G: '', B: '' };
                }
              }
            }
            if (item["Background Dot Color"]) {
              const bgColorModel = getColorModel(item["Background Dot Color"]);
              const bgDotColorObj = {
                luminescenceValues: [item["Luminescence Value1"], item["Luminescence Value2"], item["Luminescence Value3"], item["Luminescence Value4"], item["Luminescence Value5"] ?? '', item["Luminescence Value6"] ?? ''],
                percentage: item["Percentage"]
              };
              if (bgColorModel === "Lab") {
                bgDotColorObj.dotColorCielab = parseLabColor(item["Background Dot Color"]);
                bgDotColorObj.dotColorRgb = { R: '', G: '', B: '' };
                bgDotColorObj.dotColorCmyk = { C: '', M: '', Y: '', K: '' };
              } else if (bgColorModel === "RGB") {
                bgDotColorObj.dotColorRgb = parseRgbColor(item["Background Dot Color"]);
                bgDotColorObj.dotColorCielab = { L: '', A: '', B: '' };
                bgDotColorObj.dotColorCmyk = { C: '', M: '', Y: '', K: '' };
              } else if (bgColorModel === "CMYK") {
                bgDotColorObj.dotColorCmyk = parseCmykColor(item["Background Dot Color"]);
                bgDotColorObj.dotColorCielab = { L: '', A: '', B: '' };
                bgDotColorObj.dotColorRgb = { R: '', G: '', B: '' };
              }
              updatedFormData.bgDotColor.push(bgDotColorObj);
            }

            if (item["Dot Selection Type"]) {
              updatedFormData.dotSelectionType = item["Dot Selection Type"];
            }
            if (item["Min"]) {
              updatedFormData.differentDotSizeMin = item["Min"];
            }
            if (item["Max"]) {
              updatedFormData.differentDotSizeMax = item["Max"];
            }
            if (item["Custom Dots"]) {
              const customDotSizes = JSON.parse(item["Custom Dots"]);
              updatedFormData.differentDotSizes.push(...customDotSizes);
            }
            if (item["Min Distance"]) {
              updatedFormData.distanceBetweenDotsMin = item["Min Distance"];
            }
            if (item["Max Distance"]) {
              updatedFormData.distanceBetweenDotsMax = item["Max Distance"];
            }
            if (item["Frame Shape"]) {
              updatedFormData.frameShape = item["Frame Shape"];
            }
            if (item["Diameter/Height"]) {
              updatedFormData.shapeSizeDiameterOrHeight = item["Diameter/Height"];
            }
            if (item["Width"]) {
              updatedFormData.shapeSizeWidth = item["Width"];
            }
          });
          setFormData(updatedFormData);
        },
        header: true
      });
      closeUploadParameterModal();
    };
    reader.readAsText(selectedParameterFile);
  }
};

//upload user image
export const handleUserImageChange = (e, setSelectedUserImage) => {
  const file = e.target.files[0];
  if (file) {
    const imageUrl = URL.createObjectURL(file);
    setSelectedUserImage(imageUrl);
  }
};

export const handleUserImageUpload = (selectedUserImage, closeUploadParameterModal) => {
  if (selectedUserImage) {
    console.log('File uploaded:', selectedUserImage);
    // Optionally, you can close the modal after the upload
    closeUploadParameterModal();
  }
};

export const getColorModel = (colorString) => {
  if (!colorString) return '';
  const lowercaseColorString = colorString.toLowerCase();
  if (lowercaseColorString.startsWith("lab")) return "Lab";
  if (lowercaseColorString.startsWith("rgb")) return "RGB";
  if (lowercaseColorString.startsWith("cmyk")) return "CMYK";
  return '';
}

export const parseLabColor = (colorString) => {
  if (!colorString) return { L: '', A: '', B: '' };

  const labValues = colorString.match(/\(([^)]+)\)/)[1].split(',').map(parseFloat);
  return { L: labValues[0], A: labValues[1], B: labValues[2] };
}

export const parseRgbColor = (colorString) => {
  if (!colorString) return { R: '', G: '', B: '' };

  const rgbValues = colorString.match(/\(([^)]+)\)/)[1].split(',').map(parseFloat);
  return { R: rgbValues[0], G: rgbValues[1], B: rgbValues[2] };
}

export const parseCmykColor = (colorString) => {
  if (!colorString) return { C: '', M: '', Y: '', K: '' };

  const cmykValues = colorString.match(/\(([^)]+)\)/)[1].split(',').map(parseFloat);
  return { C: cmykValues[0], M: cmykValues[1], Y: cmykValues[2], K: cmykValues[3] };
}

const validateCSV = (content) => {
  // Split the content of the CSV into lines
  const lines = content.split('\n');
  
  // Get the headers (first line)
  const headers = lines[0].trim().split(',');

  // Define expected headers
  const expectedHeaders = [
    "Background Color",
    "Background Dot Color",
    "Luminescence Value1",
    "Luminescence Value2",
    "Luminescence Value3",
    "Luminescence Value4",
    "Luminescence Value5",
    "Luminescence Value6",
    "Percentage",
    "Dot Selection Type",
    "Min",
    "Max",
    "Custom Dots",
    "Min Distance",
    "Max Distance",
    "Frame Shape",
    "Diameter/Height",
    "Width"
  ];

  // Check if the uploaded CSV has the correct headers
  const isValidCSV = JSON.stringify(headers) === JSON.stringify(expectedHeaders);
  return isValidCSV
  // if (isValidCSV) {
  //   return "CSV is valid";
  // } else {
  //   return "CSV is not valid. Headers do not match."
  // }
};
