// ImageContainer.js
import React, { useEffect, useState } from 'react';
import { checkAuthToken, deleteImage, duplicateImage, getImageList, renameImage, searchImageList } from '../../auth/Authcrud';
import { useNavigate } from 'react-router-dom';
import Loader from '../commonUI/Loader';
import NoData from '../../media/Asset 1noData.png'
import ConfirmPopup from '../commonUI/ConfirmPopup';
import Tooltip from '../commonUI/Tooltip';
import { useSharedState } from '../../sharedstates/SharedStateContext';

const ImageContainer = () => {
  const [hoveredIndex, setHoveredIndex] = useState({});
  const [imageList, setImageList] = useState({})
  const [allImageList, setAllImageList] = useState({})
  const [sortedMonths, setSortedMonths] = useState([])
  const [allsortedMonths, setAllSortedMonths] = useState([])
  const [loading, setLoading] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [rename, setRename] = useState('');
  const [popupError, setPopupError] = useState('');
  const [duplicateTooltip, setDuplicateTooltip] = useState({});
  const { searchTerm } = useSharedState();

  const handleMouseEnter = (monthYear, index) => {
    setHoveredIndex({ ...hoveredIndex, [monthYear]: index });
  };

  const handleMouseLeave = (monthYear) => {
    setHoveredIndex({ ...hoveredIndex, [monthYear]: null });
  };
  
  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const openRenameModal = () => {
    setIsRenameModalOpen(true);
  };

  const closeRenameModal = () => {
    setRename('')
    setPopupError('')
    setIsRenameModalOpen(false);
  };

  const handleConfirm = () => {
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    deleteImage(imageId, authToken).then(res => {
      const imagesByMonth = groupImagesByMonth(res.data);
      // Sort keys in descending order
      const sortedMonth = Object.keys(imagesByMonth).sort((a, b) => new Date(b) - new Date(a));
      setSortedMonths(sortedMonth)
      setAllSortedMonths(sortedMonth)
      setImageList(imagesByMonth)
      setAllImageList(imagesByMonth)
    }).catch(err => {
      console.log(err)
    })
    setIsConfirmModalOpen(false);
  };

  const handleRename = () => {
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    if (rename.trim() !== '') {
      if (popupError === '') {
        renameImage(imageId, rename, authToken).then(res => {
          if (res.message === "Image already exists") {
            setPopupError('Image already exists.')
          } else {
            const imagesByMonth = groupImagesByMonth(res.data);
            // Sort keys in descending order
            const sortedMonth = Object.keys(imagesByMonth).sort((a, b) => new Date(b) - new Date(a));
            setSortedMonths(sortedMonth)
            setAllSortedMonths(sortedMonth)
            setImageList(imagesByMonth)
            setAllImageList(imagesByMonth)
            closeRenameModal()
          }
        }).catch(err => {
          console.log(err)
        })
      }
    } else {
      setPopupError('Please enter the text.')
    }
  };

  const handleDuplicateClick = (id) => {
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    duplicateImage(id, authToken).then(res => {
      if (res.message === "Image already exists") {
        setDuplicateTooltip({
          ...duplicateTooltip,
          [id]: { message: `${res.message}.`, type: 'error' }
        });
      } else {
        const imagesByMonth = groupImagesByMonth(res.data);
        // Sort keys in descending order
        const sortedMonth = Object.keys(imagesByMonth).sort((a, b) => new Date(b) - new Date(a));
        setSortedMonths(sortedMonth)
        setAllSortedMonths(sortedMonth)
        setImageList(imagesByMonth)
        setAllImageList(imagesByMonth)
      }
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setTimeout(() => {
        setDuplicateTooltip({
          ...duplicateTooltip,
          [id]: { message: '', type: '' }
        });
      }, 3000);
    });
  }

  const groupImagesByMonth = images => {
    const imagesByMonth = {};
    images.forEach(image => {
      const date = new Date(image.created_at);
      const monthYear = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(date);
      if (!imagesByMonth[monthYear]) {
        imagesByMonth[monthYear] = [];
      }
      imagesByMonth[monthYear].push(image);
    });
    return imagesByMonth;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setLoading(true)
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    getImageList(authToken).then(res => {
      const imagesByMonth = groupImagesByMonth(res.data);
      // Sort keys in descending order
      const sortedMonth = Object.keys(imagesByMonth).sort((a, b) => new Date(b) - new Date(a));
      setSortedMonths(sortedMonth)
      setAllSortedMonths(sortedMonth)
      setImageList(imagesByMonth)
      setAllImageList(imagesByMonth)
    }).catch(err => {
      console.log(err)
    }).finally(() => setLoading(false));
  }, [])

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      setLoading(true)
      const authToken = localStorage.getItem('token')
      if (!checkAuthToken(authToken)) return;
      searchImageList(searchTerm, authToken).then(res => {
        if (res.message === "Plate Name Not Found") {
          setImageList(null)
        }
        const imagesByMonth = groupImagesByMonth(res.data);
        // Sort keys in descending order
        const sortedMonth = Object.keys(imagesByMonth).sort((a, b) => new Date(b) - new Date(a));
        setSortedMonths(sortedMonth)
        setImageList(imagesByMonth)
      }).catch(err => {
        console.log(err)
      }).finally(() => setLoading(false));
    } else {
      setImageList(allImageList)
      setSortedMonths(allsortedMonths)
      setLoading(false)
    }
  }, [searchTerm, allImageList, allsortedMonths])

  return (
    <>
      <div className='bg-cover bg-center bgImage Dashboard'>
        <ConfirmPopup
          isOpen={isConfirmModalOpen} onClose={closeConfirmModal}
          title="Are you sure"
          description="you want to delete the selected image?"
          onConfirm={handleConfirm} onCancel={closeConfirmModal}
          display="hidden"
          displayDescription='block'
          type='delete'
        />
        <ConfirmPopup
          isOpen={isRenameModalOpen} onClose={closeRenameModal}
          title="Are you sure"
          description="you want to rename the selected image?"
          onConfirm={handleRename} onCancel={closeRenameModal}
          setRename={setRename}
          popupError={popupError}
          setPopupError={setPopupError}
          displayDescription="block"
          display='block'
          type='rename'
        />

        <div className="flex justify-end items-center w-full pt-5 pr-5">
          <button className='bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-sm text-white  rounded-lg px-10 md:w-[200px] h-[45px] transition-transform transform hover:scale-105 shadow-lg'
            onClick={() => navigate('/imagegenerator')}
          >NEW</button>
        </div>
        {initialLoading && <Loader />}
        {!loading ? (imageList === null || (Object.keys(imageList).length === 0)) && <div className="flex items-center justify-center" style={{ width: "100vw", height: "90vh" }}>
          <img src={NoData} alt="Logo" className='nodataimage'
          />
        </div> : <Loader />}
        {/* Container for repeated content in a responsive grid */}
        {sortedMonths.length > 0 && sortedMonths.map((monthYear, parentindex) => (
          <>
            <div className={` mt-5 text-lg font-semibold ml-5 p-2 max-w-fit rounded`}>{imageList && monthYear}</div>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 md:gap-2 2xl:gap-6 m-4">
              {imageList && imageList[monthYear].length > 0 && imageList[monthYear].map((item, index) => (
                <div
                  key={`${item}${index}`}
                  className="relative group overflow-hidden rounded-md shadow-md transition duration-300 transform hover:scale-105 relative mobileHeight"
                  onMouseEnter={() => handleMouseEnter(monthYear, index)}
                  onMouseLeave={() => handleMouseLeave(monthYear)}
                >
                  <div className='absolute w-full bottom-0 text-center text-slate-100 pt-3 pb-2' style={{ backgroundImage: "linear-gradient(transparent, rgb(107 114 128))" }}>{(item.url?.split("/")[item.url?.split("/").length - 1]?.split(".")[0]) ?? ''}</div>
                  <img src={item.url} alt="" className="w-full h-full object-cover" />
                  {hoveredIndex[monthYear] === index && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-0 hover:bg-opacity-50 transition duration-300">
                      <div className="flex flex-col items-center gap-4 text-white">
                        <button className="bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-xs text-white font-normal rounded-3xl w-[80px] h-[25px] transition-transform transform hover:scale-105 shadow-lg" onClick={() => {
                          navigate('/imageeditor', { state: { imageUrl: item.url, editImageId: item.id } })
                        }}>Edit</button>
                        <button className="bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-xs text-white font-normal rounded-3xl w-[80px] h-[25px] transition-transform transform hover:scale-105 shadow-lg"
                          onClick={() => {
                            openConfirmModal()
                            setImageId(item.id)
                          }}
                        >Delete</button>
                        <Tooltip position="top" content={duplicateTooltip[item.id] || { message: '', type: '' }}>
                          <button className="bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-xs text-white font-normal rounded-3xl w-[80px] h-[25px] transition-transform transform hover:scale-105 shadow-lg"
                            onClick={() => handleDuplicateClick(item.id)}
                          >Duplicate</button>
                        </Tooltip>
                        <button className="bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-xs text-white font-normal rounded-3xl w-[80px] h-[25px] transition-transform transform hover:scale-105 shadow-lg"
                          onClick={() => {
                            openRenameModal()
                            setImageId(item.id)
                          }}
                        >Rename</button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default ImageContainer;
