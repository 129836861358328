import React, { useEffect, useState } from 'react';
import loginBackground from '../../media/login-bg.png'
import logoImage from '../../media/logo.png'
import { FcExpired } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';

const MailFailed = () => {
    const navigate = useNavigate()
    return (
        <div className="min-h-screen flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${loginBackground})` }}>
            <div className="bg-white p-8 rounded-xl shadow-md md:w-[400px] sm:w-[350px] text-center Login">
                {/* Logo */}
                <div className="m-6 mb-16 logoSpace">
                    <img src={logoImage} alt="Logo" className="mx-auto h-16 LoginLogo" />
                </div>
                <div className="m-6 mb-12 logoSpace">
                <div className='flex justify-center'><FcExpired className="w-20 h-20 LoginLogo" /></div>
                    <h2 className="text-xl font-semibold mt-3 sm:mb-1 mb-4 text-sky-700 font-AmazonEmberBold LoginHeadline" >Session invalid or expired. Please try again later.</h2>
                </div>
                <button
                    type="button"
                    className="w-full h-[50px] mt-4 mb-2 py-2 px-3 rounded focus:outline-none focus:shadow-outline-blue bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-white"
                    onClick={()=>navigate('/')}
                >
                    OK
                </button>
            </div>
        </div>
    );
}

export default MailFailed;
