import React from 'react';
import rainbowImage from '../../media/colorpicker.png';

const PickColor = ({ inputRef, handleColorChange }) => {
    return (
        <div className='flex items-center'>
            <span className='text-xs text-gray-700 pr-6 mt-[-4px]'>Color Picker</span>
            <div className='pl-0.5 pt-0.5 bg-white absolute top-[1px] right-0 bottom-0 rounded-md w-5 h-5 flex items-center'>
                <input type="color" ref={inputRef}
                    onChange={(e) => handleColorChange(e)}
                    className="appearance-none ml-4 w-2 h-2 rounded-md border-none outline-none cursor-pointer" />
                <div
                    className='absolute top-[3px] right-0 bottom-0 left-[2px] rounded-md cursor-pointer bg-cover bg-center w-4 h-4'
                    style={{ backgroundImage: `url(${rainbowImage})` }}
                    onClick={() => inputRef.current.click()}
                ></div>
            </div>
        </div>
    );
}

export default PickColor;
