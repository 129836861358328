// Header.js
import React, { useEffect, useState } from 'react';
import { FaSearch, FaBell, FaSignOutAlt, FaUser } from 'react-icons/fa'; // Import necessary icons
import { checkAuthToken, logout, personInfoBasedOnToken } from '../../auth/Authcrud';
import logoImage from '../../media/logo.png'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useSharedState } from '../../sharedstates/SharedStateContext';

const Header = () => {
  const [user, setUser] = useState({})
  const navigate = useNavigate()
  const { searchTerm,setSearchTerm } = useSharedState();
  const [showLogoutTooltip, setShowLogoutTooltip] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    personInfoBasedOnToken(authToken).then(res => {
      if (res.msg && (res.msg === 'Token has expired' || res.msg === 'Signature verification failed')) {
        localStorage.removeItem("token");
        navigate('/')
      }
      setUser(res)
    }).catch(err => {
      console.log(err)
    })
  }, [])

  const handleLogout = (e) => {
    e.preventDefault()
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    logout(authToken).then(res => {
          setShowLogoutTooltip(true);
        setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/')
        }, 1000);
    }).catch(err => {
      console.log(err)
    })
  }
 
  const handleChange = (e) => {
    if (e.target.value.trim() === '') {
      setSearchTerm('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value.trim() !== '') {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <>
      <header className="relative z-40 bg-white px-4 py-2 flex justify-between items-center text-gray-800 shadow-md">
        <div className="mb-2 md:mb-0">
          <div className='w-full'><img
            src={logoImage}
            alt="Logo"
            className='myLogo'
          />
          </div>
        </div>

        {/* Search Bar */}
        <div className="flex items-center mx-3 mb-2 md:mb-0 fieldSearch ">
          <input
            type="text"
            placeholder="Search"
            className="w-full bg-gray-100 text-gray-800 p-2 pl-[20px] rounded-3xl"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>

        {/* User Info, Notifications, and Logout */}
        <div className="flex items-center">
          <div className="flex items-center mr-4">
            <span className="mr-2 UserName text-end">{user.fname} {user.lname}</span>
            <div><div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center">
              <FaUser className="text-gray-600" />
            </div>
            </div>
          </div>
          {/* <button className="mr-4 HeadIcon">
            <FaBell />
          </button> */}
          <div className='relative'>
          <Link onClick={(e)=>handleLogout(e)} className='HeadIcon'> <FaSignOutAlt /></Link>
          {showLogoutTooltip && 
          <div className='absolute -bottom-8 bg-green-500 right-0 w-[max-content] px-3 py-1 rounded text-xs text-white'>Logout successfully.</div>
          }
          </div>
        </div>
      </header>
      <Outlet />
    </>
  );
};

export default Header;
