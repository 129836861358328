/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import React from "react";
import RoutesArray from "./routing/RoutingPaths";
import { SharedStateProvider } from './sharedstates/SharedStateContext';

function App() {
  return (
    <>
      <div className="App">
        <SharedStateProvider>
          <RoutesArray />
        </SharedStateProvider>
      </div>
    </>
  );
}
export default App;
