// src/components/Dashboard.js
import React from 'react';
import ImageContainer from './ImageContainer';

const Dashboard = () => {
  return (
    <ImageContainer />
  );
};

export default Dashboard;
