export const loginUrl = '/login'
export const logoutUrl = '/logout'
export const basedOnTokenUrl = '/based-on-token'
export const getImageListUrl = '/get_image_list'
export const formParameterDataUrl = '/form_parameters_data'
export const editImageUrl = '/edit_image'
export const getBookmarkColorListUrl = '/get_bookmarkcolor_list'
export const bookmarkColorUrl = '/bookmark_color'
export const saveDotParameterUrl = '/save_dot_parameter'
export const saveEditDotParameterUrl = '/edit_dot_image'
export const saveAsImageUrl = '/rewrite_image'
export const duplicateImageUrl = '/duplicate_image'
export const deleteImageUrl = '/delete_image'
export const renameImageUrl = '/rename_image'
export const imageDetailUrl = '/image_detail'
export const sendEmailUrl = '/sent_mail'
export const resetPasswordUrl = '/reset_password'
export const searchImageListUrl = '/search_image_list'

const baseUrl = process.env.REACT_APP_BASE_URL

const headers = {
  'authorization': 'Bearer ' + localStorage.getItem('token'),
  'Content-Type': 'application/json'
};

export const checkAuthToken = (authToken) => {
  if (!authToken || typeof authToken !== 'string') {
    console.log("Invalid or missing authToken.");
    return false;
  }
  return true;
};

export const login = async (email, password) => {
  const data =
  {
    email: email,
    password: password
  }
  return fetch(baseUrl + loginUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const personInfoBasedOnToken = async (authToken) => {
  return fetch(baseUrl + basedOnTokenUrl, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
  }).then(res => res.json())
}

export const getImageList = async (authToken) => {
  return fetch(baseUrl + getImageListUrl, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
  }).then(res => res.json())
}

export const formParameterData = async (formData, authToken) => {
  const data = formData
  return fetch(baseUrl + formParameterDataUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const editImage = async (formData, authToken) => {
  const data = formData
  return fetch(baseUrl + editImageUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const getBookmarkColorList = async (authToken) => {
  return fetch(baseUrl + getBookmarkColorListUrl, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
  }).then(res => res.json())
}

export const bookmarkColor = async (payload, authToken) => {
  const data = payload
  return fetch(baseUrl + bookmarkColorUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const saveDotParameter = async (formData, authToken) => {
  const data = formData
  return fetch(baseUrl + saveDotParameterUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const saveEditDotParameter = async (formData, authToken) => {
  const data = formData
  return fetch(baseUrl + saveEditDotParameterUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const saveAsImage = async (formData, authToken) => {
  return fetch(baseUrl + saveAsImageUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
    },
    body: formData
  }).then(res => res.json())
}

export const logout = async (authToken) => {
  return fetch(baseUrl + logoutUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }).then(res => res.json())
}

export const duplicateImage = async (id, authToken) => {
  const data = {
    id: id
  }
  return fetch(baseUrl + duplicateImageUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const deleteImage = async (id, authToken) => {
  const data = {
    id: id
  }
  return fetch(baseUrl + deleteImageUrl, {
    method: 'DELETE',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const renameImage = async (id,name, authToken) => {
  const data = {
    id: id,
    name: name
  }
  return fetch(baseUrl + renameImageUrl, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const sendEmail = async (email) => {
  const data = {
    email : email
  }
  return fetch(baseUrl + sendEmailUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const verifyEmailToken = async (url) => {
  return fetch(baseUrl + url, {
    method: 'GET',
    headers: headers
  }).then(res => res.json())
}

export const resetPassword = async (payload) => {
  return fetch(baseUrl + resetPasswordUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload)
  }).then(res => res.json())
}

export const imageDetail = async (id, authToken) => {
  return fetch(baseUrl + `${imageDetailUrl}/${id}`, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }).then(res => res.json())
}

export const searchImageList = async (searchText, authToken) => {
  return fetch(baseUrl + `${searchImageListUrl}/${searchText}`, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }).then(res => res.json())
}