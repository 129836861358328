// SharedStateContext.js
import React, { createContext, useContext, useState } from 'react';

const SharedStateContext = createContext();

export const useSharedState = () => useContext(SharedStateContext);

export const SharedStateProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <SharedStateContext.Provider value={{ searchTerm, setSearchTerm }}>
      {children}
    </SharedStateContext.Provider>
  );
};
