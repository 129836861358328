// src/Login.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { login, sendEmail } from '../../auth/Authcrud';
import loginBackground from '../../media/login-bg.png'
import logoImage from '../../media/logo.png'
import Tooltip from '../commonUI/Tooltip';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const [loginTooltip, setLoginTooltip] = useState({ message: '', type: '' });
    const [forgetTooltip, setForgetTooltip] = useState({ message: '', type: '' });
    const location = useLocation();
    let { existingEmail } = location.state || {};

    const setToken = (token) => {
        localStorage.setItem('token', token);
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLogin = () => {
        if (!validateEmail(email)) {
            setLoginTooltip({ message: `Please enter a valid email address.`, type: 'error' });
            setTimeout(() => {
                setLoginTooltip({ message: '', type: '' });
            }, 3000);
            return
        }
        if (password.trim().length === 0) {
            setLoginTooltip({ message: `Please enter a Password.`, type: 'error' });
            setTimeout(() => {
                setLoginTooltip({ message: '', type: '' });
            }, 3000);
            return
        }
        login(email, password).then(res => {
            if (res.access_token) {
                setToken(res.access_token)
                navigate('/dashboard')
            } else {
                setLoginTooltip({ message: `${res.error}. Please try again later.`, type: 'error' });
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setTimeout(() => {
                setLoginTooltip({ message: '', type: '' });
            }, 3000);
        });
    };

    const handleForgetPassword = () => {
        if (!validateEmail(email)) {
            setForgetTooltip({ message: `Please enter a valid email address.`, type: 'error' });
            setTimeout(() => {
                setForgetTooltip({ message: '', type: '' });
            }, 3000);
            return
        }
        sendEmail(email).then(res => {
            if(res.token){
                setForgetTooltip({ message: 'An email has been sent. Please check your inbox.', type: 'success' });
                setTimeout(() => {
                    setForgetTooltip({ message: '', type: '' });
                }, 3000);
            }else{
                setForgetTooltip({ message: "The email couldn't be sent. Please try again later..", type: 'error' });
                setTimeout(() => {
                    setForgetTooltip({ message: '', type: '' });
                }, 3000);
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        const authToken = localStorage.getItem('token')
        if (authToken) {
            navigate('/dashboard')
        }
        if(existingEmail !== undefined || existingEmail !== null || existingEmail !== ''){
            setEmail(existingEmail)
        }
    },[existingEmail, navigate])

    return (
        <div className="min-h-screen flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${loginBackground})` }}>
            <div className="bg-white p-8 rounded-xl shadow-md md:w-[400px] sm:w-[350px] text-center Login">
                {/* Logo */}
                <div className="m-6 mb-16 logoSpace">
                    <img src={logoImage} alt="Logo" className="mx-auto h-16 LoginLogo" />
                </div>
                <h2 className="text-2xl font-semibold sm:mb-1 mb-4 text-sky-700 font-AmazonEmberBold LoginHeadline" >LOGIN</h2>

                <form className='mt-16 LoginForm'>
                    {/* Username */}
                    <div className="mb-4">
                        <input
                            type="text"
                            id="email"
                            name="email"
                            className="border rounded w-full py-2 px-3 h-[30px] text-xs text-gray-700"
                            placeholder="Username"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    {/* Password */}
                    <div className="mb-2 relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="border rounded w-full py-2 px-3 h-[30px] text-xs text-gray-700 pr-10"
                            placeholder="Password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* Toggle button to show/hide password */}
                        <button
                            type="button"
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaEye className='text-blue-500' /> : <FaEyeSlash />}
                        </button>
                    </div>
                    {/* Forget Password */}
                    <Tooltip position="top" content={forgetTooltip}>
                    <div className="mb-2 text-right">
                        <span className="text-blue-500 text-xs hover:underline cursor-pointer"
                            onClick={() => handleForgetPassword()}
                        >Forgot Password?</span>
                    </div>
                    </Tooltip>
                    {/* Login Button */}
                    <Tooltip position="top" content={loginTooltip}>
                        <button
                            type="button"
                            className="w-full h-[50px] mt-4 mb-2 py-2 px-3 rounded focus:outline-none focus:shadow-outline-blue bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-white"
                            onClick={handleLogin}
                        >
                            LOG IN
                        </button>
                    </Tooltip>
                </form>
            </div>
        </div>
    );
};

export default Login;
