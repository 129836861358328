import * as Yup from 'yup';

export const validateFormData = async (schema, formData) => {
  try {
    await schema.validate(formData, { abortEarly: false });
    const result = {
      isvalid: true,
      data: formData
    }
    return Promise.resolve(result);
  } catch (validationError) {
    const errors = validationError.inner.map((error) => ({
      field: error.path,
      message: error.message,
    }));
    const errorsObject = errors.reduce((acc, error) => {
      acc[error.field] = error.message;
      return acc;
    }, {});
    return Promise.reject(errorsObject);
  }
};

export const validateColors = (colors) => {
  const { bgColorCielab, bgColorRgb, bgColorCmyk } = colors;

  const isFilled = (colorSpace) => Object.values(colorSpace).every(val => val !== '');
  const isSomeFilled = (colorSpace) => Object.values(colorSpace).some(val => val !== '');
  const isSomeEmpty = (colorSpace) => Object.values(colorSpace).some(val => val === '');

  if (!(isFilled(bgColorCielab) || isFilled(bgColorRgb) || isFilled(bgColorCmyk))) {
    return "At least one color space should be filled.";
  } 
  if (isSomeFilled(bgColorRgb) && isSomeEmpty(bgColorRgb)) {
    return "Please enter a valid RGB value.";
  }
  if (isSomeFilled(bgColorCielab) && isSomeEmpty(bgColorCielab)) {
    return "Please enter a valid CIELAB value.";
  }
  if (isSomeFilled(bgColorCmyk) && isSomeEmpty(bgColorCmyk)) {
    return "Please enter a valid CMYK value.";
  }
};


export const luminescenceValuesSchema = Yup.array()
  .of(Yup.string())
  .test('at-least-one-provided', 'At least one luminescence value should be provided', function (values) {
    const filteredArray = values.filter(item => item !== '');
    return filteredArray.length > 0;
  })
  .required('Luminescence values are required');

export const PercentageSchema = Yup.string()
  .required('Percentage is required');

export const schema = Yup.object().shape({
  differentDotSizeMin: Yup.string()
    .required('Minimum different dot size is required'),
  differentDotSizeMax: Yup.string()
    .required('Maximum dot size is required'),
  differentDotSizes: Yup.array()
    .of(Yup.string())
    .test('at-least-one-provided', 'At least one dot size should be provided', function (values) {
      const filteredArray = values.filter(item => item !== '');
      return filteredArray.length > 0;
    })
    .required('dot sizes are required'),
  distanceBetweenDotsMin: Yup.string()
    .test('valid or invalid', 'Minimum distance must not exceed 2.', function (value) {
      // Check if the value is a valid number or float and within the specified range
      return /^$|^(?:0(?:\.\d)?|1(?:\.\d)?|2(?:\.0)?)$/.test(value);
    })
    .required('Minimum distance is required'),
  distanceBetweenDotsMax: Yup.string()
    .test('valid or invalid', 'Maximum distance must not exceed 3.', function (value) {
      // Check if the value is a valid number or float and within the specified range
      return /^$|^(?:0(?:\.\d)?|1(?:\.\d)?|2(?:\.\d)?|3(?:\.0)?)$/.test(value);
    })
    .required('Maximum distance is required'),
  shapeSizeDiameterOrHeight: Yup.string()
    .required('Diameter or height is required'),
  shapeSizeWidth: Yup.string()
    .required('Width is required'),
});

export const validateGenerateImageForm = async (formData, dotSelectionType, allValidations, setFormError) => {
  try {

    // Validate formData
    let validations = await validateFormData(schema, formData)
      .catch((err) => {
        if (formData.frameShape === 'circle') {
          delete err.shapeSizeWidth;
        }
        if (dotSelectionType === 'dotrange') {
          delete err.differentDotSizes;
        } else {
          delete err.differentDotSizeMin;
          delete err.differentDotSizeMax;
        }
        return err;
      });

    // Validate bgColor
    const bgColorValidation = validateColors({
      bgColorCielab: formData.bgColorCielab,
      bgColorRgb: formData.bgColorRgb,
      bgColorCmyk: formData.bgColorCmyk
    });

    let allPercentage = []
    formData.bgDotColor.map((section, index) => {
      allPercentage.push(parseFloat(section.percentage))
    })
    let totalPercentage = allPercentage.reduce((sum, entry) => {
      return sum + entry;
    }, 0);

    // Validate bgDotColor and luminescenceValues in parallel
    const bgDotColorValidationResults = await Promise.all(formData.bgDotColor.map(async (schema, index) => {
      const dotColorValidation = validateColors({
        bgColorCielab: schema.dotColorCielab,
        bgColorRgb: schema.dotColorRgb,
        bgColorCmyk: schema.dotColorCmyk
      });

      let luminescenceValuesError = null;
      let percentageError = null;

      try {
        await validateFormData(luminescenceValuesSchema, schema.luminescenceValues);
      } catch (err) {
        luminescenceValuesError = err['']; // Store luminescenceValues error
      }

      if (formData.bgDotColor.length > 1) {
        try {
          await validateFormData(PercentageSchema, schema.percentage);
        } catch (err) {
          percentageError = err['']; // Store percentage error
        }

        // Check for percentage error
        if (totalPercentage !== 100) {
          percentageError = 'Sum must be 100.';
        }
      }

      return {
        [`bgDotColor${index}`]: {
          dotColorValidation,
          luminescenceValuesValidation: luminescenceValuesError,
          percentage: percentageError
        }
      };
    }));

    allValidations = {
      validations,
      bgColor: bgColorValidation,
      bgDotColorValidation: Object.assign({}, ...bgDotColorValidationResults)
    };
    setFormError(allValidations)
  } catch (error) {
    console.error("Error occurred during form validation:", error);
  }
};

export const isObjectEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      if (!isObjectEmpty(obj[key])) {
        return false;
      }
    } else if (obj[key] !== null && obj[key] !== undefined) {
      return false;
    }
  }
  return true;
}