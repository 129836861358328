/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
import ColorThief from 'colorthief';
import interact from 'interact.js';
import { getColorModel, parseCmykColor, parseLabColor, parseRgbColor } from './UserUpload';
export const rgbToHex = (rgb) => {
  const [r, g, b] = rgb.match(/\d+/g).map(Number);
  return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
};

export const hexToRgb = (hex) => {
  // Remove '#' if present
  hex = hex.replace(/^#/, '');
  // Parse the hex values into their respective RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export const handleCircleClick = async (event, inputColor) => {
  event.stopPropagation();
  const circle = event.target.closest('circle');
  if (circle) {
    // Check if the circle matches the criteria to be prevented from filling
    const cx = circle.getAttribute('cx');
    const cy = circle.getAttribute('cy');
    const r = circle.getAttribute('r');
    if (!(cx === '50%' && cy === '50%' && r === '35%')) {
      // Check the size of the circle
      const circleRadius = parseFloat(circle.getAttribute('r'));
      const isLargeCircle = circleRadius > 50; // Adjust the threshold as needed
      if (!isLargeCircle) {
        const computedStyle = window.getComputedStyle(circle);
        const currentColor = computedStyle.getPropertyValue('fill');
        const currentColorHex = rgbToHex(currentColor);
        // Simulating an asynchronous operation with setTimeout
        await new Promise(resolve => setTimeout(resolve, 100));
        // Toggle fill color
        circle.style.fill = currentColorHex === inputColor ? '' : inputColor;
      }
    }
  }
};

export const handleResize = (svgRef, textElement, svgWidth, svgHeight, zoomLevel = 1) => {
  if (svgRef.current) {
    const viewBoxWidth = svgWidth / zoomLevel;
    const viewBoxHeight = svgHeight / zoomLevel;
    const viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
    svgRef.current.setAttribute('viewBox', viewBox);
    // Calculate font size based on the minimum dimension of SVG
    const minDimension = Math.min(svgWidth, svgHeight);
    let fontSize = minDimension * 0.3;
    // Check if the shape is a rectangle
    if (svgWidth !== svgHeight) {
      // Adjust the font size for rectangles
      fontSize = minDimension * 0.6;
      textElement.setAttribute('y', '58%');
    }
    // For circles, consider using diameter or radius
    if (svgWidth === svgHeight && svgWidth > 0) {
      const radius = svgWidth / 2;
      // Adjust font size based on the radius
      const adjustedFontSize = Math.min(radius * 2, fontSize);
      textElement.setAttribute('font-size', adjustedFontSize);
    } else {
      textElement.setAttribute('font-size', fontSize);
    }
  }
};
let callCount = 0
export const handleActiveTextEdit = async (event, textElement, setIsDragging) => {
  event.stopPropagation();
  const currentPointerEvents = textElement.getAttribute('pointer-events') || 'none';
  const newPointerEvents = currentPointerEvents === 'auto' ? 'none' : 'auto';
  textElement.setAttribute('pointer-events', newPointerEvents);
  callCount++;
  const parts = window.location.href.split("/");
  const currentPage = parts[parts.length - 1];
  if (currentPage === 'imageeditor') {
    callCount % 2 === 0 && setIsDragging(prevIsDragging => !prevIsDragging)
  } else {
    setIsDragging(prevIsDragging => !prevIsDragging)
  }
};

export const handleRemoveBorderElement = (textElement) => {
  textElement.setAttribute('stroke', 'rgba(0, 0, 0, 0.6');
  textElement.setAttribute('stroke-width', '0');
  // Force redraw of the SVG container
  const parentDiv = document.getElementById('svgparent');
  // Temporarily hide the SVG container
  parentDiv.style.opacity = '0';
  // Trigger reflow
  parentDiv.offsetHeight;
  // Show the SVG container again
  parentDiv.style.opacity = '1';
};

export const fetchDataAndResize = async (imageUrl, svgContainer, textElement, formData, svgRef, setRecentlyUsedColors, setIsDragging, setSvgSize, selectedFont) => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch SVG file');
    }

    const browser = detectBrowser();
    let fonts = ['Arial Black', 'DejaVu Serif', 'Franklin Gothic', 'Verdana'];
    let symbols = ['@', '%', 'W'];
    let smallLettersSet = ['m', 'w'];
    let smallAlphabetArray = Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i));

    const hasRepeatingLetters = (input) => {
      for (let i = 0; i < input.length - 1; i++) {
        if (smallAlphabetArray.includes(input[i]) && smallAlphabetArray.includes(input[i + 1])) {
          return true;
        }
      }
      return false;
    };

    const hasMWWithOtherCharacter = (input) => {
      const containsM = input.includes('m');
      const containsW = input.includes('w');
      return (containsM || containsW) && (input.length > 1) && (containsM !== containsW) && !(['mm', 'mw', 'ww', 'wm'].includes(numberToDraw));
    };

    const numberToDraw = formData.numberToDraw;
    const characters = numberToDraw.split('');

    // Function to check if a character is not a number
    const isNotNumber = (char) => {
      return isNaN(char);
    };
    const hasNonNumericCharacter = characters.some(isNotNumber);

    const svgText = await response.text();
    svgContainer.innerHTML = svgText;

    // Parse SVG content to extract width and height
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
    const svgElement = svgDoc.querySelector('svg');

    const blob = new Blob([svgText], { type: 'image/svg+xml;charset=utf-8' });
    const modifiedImageUrl = URL.createObjectURL(blob);
    const image = new Image();
    image.src = modifiedImageUrl;
    const colorThief = new ColorThief();
    image.onload = () => {
      const palette = colorThief.getPalette(image);
      const rgbValues = palette.map(color => (`rgb(${color.join(', ')})`));
      setRecentlyUsedColors(rgbValues)
    };

    const svgWidth = svgElement.getAttribute('width');
    const svgHeight = svgElement.getAttribute('height');
    setSvgSize({ width: svgWidth, height: svgHeight })

    const parentDiv = document.getElementById('svgparent');
    parentDiv.style.width = `${svgWidth}px`;
    parentDiv.style.height = `${svgHeight}px`;

    textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.setAttribute('x', '46%');
    textElement.setAttribute('y', '55%');
    textElement.setAttribute('text-anchor', 'middle');
    textElement.setAttribute('dominant-baseline', 'middle');
    textElement.setAttribute('fill', 'rgba(0, 0, 0, 0.0)');
    textElement.setAttribute('font-family', `${selectedFont.value}`);
    textElement.setAttribute('font-style', 'italic');
    textElement.setAttribute('contentEditable', 'true'); // Allow editing
    textElement.setAttribute('id', 'svgText');
    textElement.setAttribute('pointer-events', 'none'); // Allow interaction with elements underneath
    textElement.setAttribute('stroke', 'rgba(0, 0, 0, 0.6');
    textElement.setAttribute('stroke-width', '3');

    const svgContainsCircle = document.querySelector('svg circle') !== null;
    const svgContainsRect = document.querySelector('svg rect') !== null;

    if (characters.length === 1) {
      characters.forEach((char, index) => {
        const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        if (browser === "Safari") {
          tspan.setAttribute('x', `45%`);
          textElement.setAttribute('y', '65%');
        } else {
          tspan.setAttribute('x', `46%`);
        }
        tspan.textContent = char;
        textElement.appendChild(tspan);
      });
    } else {
      let largestElement;
      if (svgContainsCircle && svgContainsRect) {
        // Compare the areas to determine the largest element
        const circle = document.querySelector('svg circle');
        const circleArea = Math.PI * Math.pow(parseFloat(circle.getAttribute('r')), 2);

        const rect = document.querySelector('svg rect');
        const rectWidth = parseFloat(rect.getAttribute('width'));
        const rectHeight = parseFloat(rect.getAttribute('height'));
        const rectArea = rectWidth * rectHeight;

        largestElement = rectArea > circleArea ? 'rect' : 'circle';
      } else if (svgContainsCircle) {
        largestElement = 'circle';
      } else if (svgContainsRect) {
        largestElement = 'rect';
      }

      if (largestElement === 'circle') {
        // Adjust positioning based on circle
        characters.forEach((char, index) => {
          const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
          let gapValue = hasNonNumericCharacter ?
            (fonts.includes(selectedFont.value) ?
              (symbols.includes(char) ? numberToDraw === 'MW' ? -35 : -31 :
                (hasRepeatingLetters(numberToDraw) ?
                  ([...numberToDraw].some(char => smallLettersSet.includes(char)) ?
                    hasMWWithOtherCharacter(numberToDraw) ? -24 : -29 : -21)
                  : -25)
              )
              :
              (symbols.includes(char) ? numberToDraw === 'MW' ? -29 : -27 :
                (hasRepeatingLetters(numberToDraw) ?
                  ([...numberToDraw].some(char => smallLettersSet.includes(char)) ?
                    hasMWWithOtherCharacter(numberToDraw) ? -20 : -23
                    : -16)
                  : -20)
              )
            )
            :
            (fonts.includes(selectedFont.value) ? -20 : -16);
          if (browser === "Safari") {
            fonts.includes(selectedFont.value) ?
              symbols.includes(char) ? tspan.setAttribute('x', `${30 - index * gapValue}%`) : tspan.setAttribute('x', `${37 - index * gapValue}%`) :
              symbols.includes(char) ? tspan.setAttribute('x', `${37 - index * gapValue}%`) : tspan.setAttribute('x', `${41 - index * gapValue}%`)
            textElement.setAttribute('y', '65%');
          } else if (browser === "Firefox") {
            fonts.includes(selectedFont.value) ?
              symbols.includes(char) ? tspan.setAttribute('x', `${30 - index * gapValue}%`) : tspan.setAttribute('x', `${37 - index * gapValue}%`) :
              symbols.includes(char) ? tspan.setAttribute('x', `${35 - index * gapValue}%`) : tspan.setAttribute('x', `${39 - index * gapValue}%`)
          } else {
            fonts.includes(selectedFont.value) ?
              symbols.includes(char) ? tspan.setAttribute('x', `${31 - index * gapValue}%`) : tspan.setAttribute('x', `${38 - index * gapValue}%`) :
              symbols.includes(char) ? tspan.setAttribute('x', `${38 - index * gapValue}%`) : tspan.setAttribute('x', `${42 - index * gapValue}%`)
          }
          tspan.textContent = char;
          textElement.appendChild(tspan);
        });
      } else if (largestElement === 'rect') {
        // Adjust positioning based on rectangle
        const rect = document.querySelector('svg rect');
        const rectWidth = parseFloat(rect.getAttribute('width'));
        const rectHeight = parseFloat(rect.getAttribute('height'));
        const rectX = parseFloat(rect.getAttribute('x'));
        const rectCenterX = rectX + rectWidth / 2; // Calculate the center X coordinate of the rectangle
        const rectCenterY = parseFloat(rect.getAttribute('y')) + rectHeight / 2; // Calculate the center Y coordinate of the rectangle
        const textLength = characters.length;
        let gap
        // Calculate the gap between characters 1 and 8 based on the rectangle's dimensions
        if (rectWidth === rectHeight) {
          let newGapdiv = hasNonNumericCharacter ?
            (fonts.includes(selectedFont.value) ?
              (symbols.includes(...characters) ? 3.2 :
                (hasRepeatingLetters(numberToDraw) ?
                  ([...numberToDraw].some(char => smallLettersSet.includes(char)) ?
                    hasMWWithOtherCharacter(numberToDraw) ? 3.9 : 3.5 : 4.6)
                  : numberToDraw === 'MW' ? 3.4 : 3.8)
              )
              :
              (symbols.includes(...characters) ? 3.6 :
                (hasRepeatingLetters(numberToDraw) ?
                  ([...numberToDraw].some(char => smallLettersSet.includes(char)) ?
                    hasMWWithOtherCharacter(numberToDraw) ? 4.6 : 4.2 : 4.8)
                  : numberToDraw === 'MW' ? 4.0 : 4.4)
              )
            )
            :
            (fonts.includes(selectedFont.value) ? 4.4 : 5.2);
          gap = Math.min(rectWidth, rectHeight) / newGapdiv;
        } else {
          let newGapdiv = hasNonNumericCharacter ?
            (fonts.includes(selectedFont.value) ?
              (symbols.includes(...characters) ? 1.6 :
                (hasRepeatingLetters(numberToDraw) ?
                  ([...numberToDraw].some(char => smallLettersSet.includes(char)) ?
                    hasMWWithOtherCharacter(numberToDraw) ? 2.0 : 1.7 : 2.2)
                  : numberToDraw === 'MW' ? 1.7 : 1.9)
              )
              :
              (symbols.includes(...characters) ? 1.8 :
                (hasRepeatingLetters(numberToDraw) ?
                  ([...numberToDraw].some(char => smallLettersSet.includes(char)) ?
                    hasMWWithOtherCharacter(numberToDraw) ? 2.3 : 2.0 : 2.6)
                  : numberToDraw === 'MW' ? 2.0 : 2.2)
              )
            )
            :
            (fonts.includes(selectedFont.value) ? 2.3 : 2.7);
          gap = Math.min(rectWidth, rectHeight) / newGapdiv;
        }

        // Calculate the total width of the text element
        const totalTextWidth = (textLength - 1) * gap;

        // Calculate the starting X position for centering the text element
        const startX = rectCenterX - totalTextWidth / 2;

        characters.forEach((char, index) => {
          const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
          const xPos = startX + index * gap; // Calculate the X position for the current character
          const yPos = rectCenterY + 10; // Adjust Y position as needed
          if (rectWidth < rectHeight) {
            if (browser === "Safari") {
              tspan.setAttribute('x', `${xPos - 27}px`)
              tspan.setAttribute('y', `${yPos + 70}px`);
            } else if (browser === "Firefox") {
              tspan.setAttribute('x', `${xPos - 35}px`);
              tspan.setAttribute('y', `${yPos + 15}px`);
            } else {
              tspan.setAttribute('x', `${xPos - 15}px`);
              tspan.setAttribute('y', `${yPos + 10}px`);
            }
          } else {
            if (browser === "Safari") {
              tspan.setAttribute('x', `${xPos + 5}px`);
              tspan.setAttribute('y', `${yPos + 35}px`);
            } else if (browser === "Firefox") {
              tspan.setAttribute('x', `${xPos - 10}px`);
              tspan.setAttribute('y', `${yPos - 3}px`);
            } else {
              tspan.setAttribute('x', `${xPos}px`);
              tspan.setAttribute('y', `${yPos}px`);
            }
          }
          tspan.textContent = char;
          textElement.appendChild(tspan);
        });
      }
    }

    // Remove any existing text elements
    svgContainer.querySelectorAll('text').forEach((text) => {
      text.remove();
    });

    // Append the new text element
    svgContainer.appendChild(textElement);

    handleResize(svgRef, textElement, svgWidth, svgHeight); // Call handleResize once SVG is loaded

    const zoomInTextElement = document.getElementById('zoomintextelement');
    const zoomOutTextElement = document.getElementById('zoomouttextelement');
    const dragTextElement = document.getElementById('drag');
    const removeBorderElement = document.getElementById('removeborder');

    // Add click event listeners to the zoom in and zoom out buttons
    zoomInTextElement?.addEventListener('click', () => handleZoomTextElement(textElement, 'in'));
    zoomOutTextElement?.addEventListener('click', () => handleZoomTextElement(textElement, 'out'));
    dragTextElement?.addEventListener('click', (e) => handleActiveTextEdit(e, textElement, setIsDragging));
    removeBorderElement?.addEventListener('click', () => handleRemoveBorderElement(textElement));

    const zoomInButton = document.getElementById('zoomin');
    const zoomOutButton = document.getElementById('zoomout');

    handleZoom(svgRef, textElement, svgWidth, svgHeight, parentDiv, 'in')
    handleZoom(svgRef, textElement, svgWidth, svgHeight, parentDiv, 'out')

    // Add click event listeners to the plus and minus buttons
    zoomInButton.addEventListener('click', () => debouncedHandleZoom(svgRef, textElement, svgWidth, svgHeight, parentDiv, 'in'));
    zoomOutButton.addEventListener('click', () => debouncedHandleZoom(svgRef, textElement, svgWidth, svgHeight, parentDiv, 'out'));

    // Function to update text position, dimensions, and rotation
    const updateText = (event) => {
      const target = event.target;
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`;

      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);

      // Get the current scale of the text element
      const currentScale = parseFloat(textElement.getAttribute('data-scale')) || 1;

      // Retrieve rotation angle
      const rotationAngle = parseFloat(target.getAttribute('data-rotation-angle')) || 0;
      target.style.transform += ` rotate(${rotationAngle}deg) scale(${currentScale})`; // Add rotation transformation
      // Force redraw of the SVG container
      const parentDiv = document.getElementById('svgparent');
      // Temporarily hide the SVG container
      parentDiv.style.opacity = '0';
      // Trigger reflow
      parentDiv.offsetHeight;
      // Show the SVG container again
      parentDiv.style.opacity = '1';
    };

    // Drag functionality using interact.js
    interact(textElement).draggable({
      onmove: updateText, // Update text position during drag
    });

    const rotateHandle = document.getElementById('rotate');
    // Rotate functionality using interact.js
    interact(rotateHandle).draggable({
      onstart: (event) => {
        const target = event.target;
        const textRect = textElement.getBoundingClientRect();
        // Adjust for scroll positions
        const scrollX = window.scrollX;
        const scrollY = window.scrollY;
        const centerX = textRect.left + textRect.width / 2 + scrollX;
        const centerY = textRect.top + textRect.height / 2 + scrollY;

        target.setAttribute('data-center-x', centerX);
        target.setAttribute('data-center-y', centerY);

        // Calculate initial angle between the current position and the center
        const initialX = event.clientX;
        const initialY = event.clientY;
        const initialAngle = Math.atan2(initialY - centerY, initialX - centerX) * (180 / Math.PI);
        target.setAttribute('data-initial-angle', initialAngle);
      },
      onmove: (event) => {
        const target = event.target;
        const centerX = parseFloat(target.getAttribute('data-center-x'));
        const centerY = parseFloat(target.getAttribute('data-center-y'));
        const currentX = event.clientX;
        const currentY = event.clientY;

        // Calculate the initial angle and the current angle
        const initialAngle = parseFloat(target.getAttribute('data-initial-angle'));
        const currentAngle = Math.atan2(currentY - centerY, currentX - centerX) * (180 / Math.PI);

        // Calculate the total angle of rotation
        let totalAngle = currentAngle - initialAngle;

        // Normalize the angle to be between 0 and 360 degrees
        totalAngle = (totalAngle + 360) % 360;

        // Get the current scale of the text element
        const currentScale = parseFloat(textElement.getAttribute('data-scale')) || 1;

        // Get the bounding box of the text element
        const bbox = textElement.getBBox();
        // Calculate the center of the bounding box
        const textcenterX = bbox.x + bbox.width / 2;
        const textcenterY = bbox.y + bbox.height / 2;

        // Apply rotation transformation centered at the center of the text element, considering the current scale
        textElement.setAttribute('data-rotation-angle', totalAngle); // Store rotation angle for future reference

        textElement.setAttribute('transform-origin', `${textcenterX} ${textcenterY}`);

        textElement.style.transform = `translate(${event.dx}px, ${event.dy}px) rotate(${totalAngle}deg) scale(${currentScale})`; // Apply translation, rotation, and scale
        // Force redraw of the SVG container
        const parentDiv = document.getElementById('svgparent');
        // Temporarily hide the SVG container
        parentDiv.style.opacity = '0';
        // Trigger reflow
        parentDiv.offsetHeight;
        // Show the SVG container again
        parentDiv.style.opacity = '1';
      },
    });

    return () => {
      zoomInTextElement?.removeEventListener('click', () => handleZoomTextElement(textElement, 'in'));
      zoomOutTextElement?.removeEventListener('click', () => handleZoomTextElement(textElement, 'out'));
      dragTextElement?.removeEventListener('click', (e) => handleActiveTextEdit(e, textElement, setIsDragging));
      removeBorderElement?.removeEventListener('click', () => handleRemoveBorderElement(textElement));

      // remove click event listeners to the plus and minus buttons
      zoomInButton.removeEventListener('click', () => debouncedHandleZoom(svgRef, textElement, svgWidth, svgHeight, parentDiv, 'in'));
      zoomOutButton.removeEventListener('click', () => debouncedHandleZoom(svgRef, textElement, svgWidth, svgHeight, parentDiv, 'out'));
      interact(textElement).unset();
    }
  } catch (error) {
    console.error('Error fetching SVG:', error);
  }
};

const handleZoomTextElement = (textElement, zoomDirection) => {
  const currentScale = parseFloat(textElement.getAttribute('data-scale')) || 1;
  const zoomFactor = zoomDirection === 'in' ? 1.1 : 0.9; // Increase or decrease scale based on zoom direction
  const newScale = currentScale * zoomFactor;
  textElement.setAttribute('transform', `scale(${newScale})`);
  textElement.setAttribute('data-scale', newScale);

  // Get the bounding box of the text element
  const bbox = textElement.getBBox();
  // Calculate the center of the bounding box
  const centerX = bbox.x + bbox.width / 2;
  const centerY = bbox.y + bbox.height / 2;
  // Apply rotation transformation centered at the center of the text element
  const rotationAngle = parseFloat(textElement.getAttribute('data-rotation-angle')) || 0;
  // Set the transform origin to the center of the text element
  textElement.setAttribute('transform-origin', `${centerX} ${centerY}`);
  textElement.style.transform = `rotate(${rotationAngle}deg) scale(${newScale})`;
  // Force redraw of the SVG container
  const parentDiv = document.getElementById('svgparent');
  // Temporarily hide the SVG container
  parentDiv.style.opacity = '0';
  // Trigger reflow
  parentDiv.offsetHeight;
  // Show the SVG container again
  parentDiv.style.opacity = '1';
};

export const handleZoom = (svgRef, textElement, svgWidth, svgHeight, parentDiv, direction) => {
  // Define the zoom scale factor
  const zoomScaleFactor = 0.05;

  // Get the current zoom level or default to 1
  const currentZoomLevel = parseFloat(svgRef.current.dataset.zoomLevel) || 1;

  // Calculate the new zoom level based on the direction
  const newZoomLevel = direction === 'in' ? currentZoomLevel + zoomScaleFactor : currentZoomLevel - zoomScaleFactor;

  // Ensure the zoom level stays within a reasonable range (e.g., between 0.2 and 5)
  const clampedZoomLevel = Math.min(Math.max(newZoomLevel, 0.2), 5);

  // Update the zoom level in the dataset
  svgRef.current.dataset.zoomLevel = clampedZoomLevel;

  // Calculate the new dimensions of the SVG
  const newSvgWidth = svgWidth * clampedZoomLevel;
  const newSvgHeight = svgHeight * clampedZoomLevel;

  // Update the size of the parent container
  parentDiv.style.width = `${newSvgWidth}px`;
  parentDiv.style.height = `${newSvgHeight}px`;

  // Calculate the new position of the SVG content
  const offsetX = (newSvgWidth - svgWidth) / 2;
  const offsetY = (newSvgHeight - svgHeight) / 2;

  // Adjust the viewBox attribute of the SVG to position it correctly
  const viewBoxX = -offsetX / clampedZoomLevel;
  const viewBoxY = -offsetY / clampedZoomLevel;
  const viewBoxWidth = newSvgWidth / clampedZoomLevel;
  const viewBoxHeight = newSvgHeight / clampedZoomLevel;
  const viewBox = `${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`;
  svgRef.current.setAttribute('viewBox', viewBox);

  // Apply a transform to the SVG content to reflect the zoom level and offset
  const transformValue = `scale(${clampedZoomLevel}) translate(${offsetX}px, ${offsetY}px)`;
  svgRef.current.style.transform = transformValue;

  // Call handleResize with the updated zoom level
  handleResize(svgRef, textElement, newSvgWidth, newSvgHeight, clampedZoomLevel);

  // Adjust textElement font size based on the zoom level
  const minDimension = Math.min(newSvgWidth, newSvgHeight);
  let fontSize = minDimension * 0.3;

  if (newSvgWidth !== newSvgHeight) {
    // Adjust the font size for rectangles
    fontSize = minDimension * 0.6;
    textElement.setAttribute('y', '58%');
  }

  // Adjust font size based on the zoom level, using a factor to compensate for zoom
  const zoomFactor = 1 / clampedZoomLevel; // Inverse of zoom level
  fontSize *= zoomFactor;

  if (newSvgWidth === newSvgHeight && newSvgWidth > 0) {
    const radius = (Math.min(newSvgWidth, newSvgHeight) / 2) / clampedZoomLevel; // Adjust radius based on zoom level
    const adjustedFontSize = Math.min(radius * 2, fontSize);
    textElement.setAttribute('font-size', adjustedFontSize);
  } else {
    textElement.setAttribute('font-size', fontSize);
  }
};

// Define a debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// Debounce the handleZoom function with a delay of 100 milliseconds
const debouncedHandleZoom = debounce(handleZoom, 100);

export const saveSVG = (svgContent, filename, width, height, type) => {
  try {
    // Create a new SVG element
    const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgElement.setAttribute("width", width);
    svgElement.setAttribute("height", height);

    // Append the SVG content to the new SVG element
    svgElement.innerHTML = svgContent;
    const textElement = svgElement.getElementById('svgText')
    textElement.setAttribute('stroke', 'rgba(0, 0, 0, 0.6');
    textElement.setAttribute('stroke-width', '0');
    // Get the updated SVG content
    const updatedSvgContent = new XMLSerializer().serializeToString(svgElement);

    // Create a new Blob with the updated SVG content
    const svgBlob = new Blob([updatedSvgContent], { type: 'image/svg+xml' });

    if (type === 'save_as_image') {
      // Create an anchor element to trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(svgBlob);
      downloadLink.download = filename || 'modified_svg.svg'; // Set the filename
      downloadLink.style.display = 'none';

      // Append the anchor element to the body and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up by removing the anchor element
      document.body.removeChild(downloadLink);

      // Revoke the SVG URL to release resources
      URL.revokeObjectURL(downloadLink.href);
    }
    return svgBlob;
  } catch (error) {
    console.error('Error saving SVG:', error);
  }
};

export const handleAddInput = (setFormData) => {
  setFormData((prevData) => ({
    ...prevData,
    differentDotSizes: [...prevData.differentDotSizes, ''],
  }));
};

export const handleRemoveInput = (index, setFormData) => {
  setFormData((prevData) => {
    const updatedDotSizes = [...prevData.differentDotSizes];
    updatedDotSizes.splice(index, 1);
    return {
      ...prevData,
      differentDotSizes: updatedDotSizes,
    };
  });
};

export const handleAddDotColor = (formData, setFormData) => {
  const newDotColorSection = { dotColorCielab: { L: '', A: '', B: '' }, dotColorRgb: { R: '', G: '', B: '' }, dotColorCmyk: { C: '', M: '', Y: '', K: '' }, luminescenceValues: ['', '', '', '', '', ''], percentage: '' };
  const updatedBgDotColor = [...formData.bgDotColor, newDotColorSection];
  setFormData((prevFormData) => ({
    ...prevFormData,
    bgDotColor: updatedBgDotColor,
  }));
};

export const handleRemoveDotColor = (formData, setFormData, indexToRemove) => {
  if (indexToRemove >= 0 && indexToRemove < formData.bgDotColor.length) {
    const updatedBgDotColor = [...formData.bgDotColor];
    updatedBgDotColor.splice(indexToRemove, 1); // Remove the element at the specified index
    setFormData({
      ...formData,
      bgDotColor: updatedBgDotColor,
    });
  }
};

const detectBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.includes("Firefox")) {
    browserName = "Firefox";
  } else if (userAgent.includes("Edg")) {
    browserName = "Microsoft Edge";
  } else if (userAgent.includes("Chrome")) {
    browserName = "Chrome";
  } else if (userAgent.includes("Safari")) {
    browserName = "Safari";
  } else if (userAgent.includes("AppleWebKit")) {
    browserName = "WebKit-based Browser";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Unknown";
  }

  return browserName;
};

export const inputStringToArray = (inputString) => {
  if(inputString !== ''){
    return inputString.match(/\d+/g).map(Number);
  }
};

export const handleEditParameterData = (data, formData, setFormData, setSelectedFont, setInputColor, setSelectedColor, setInputColorLuminescenceValues) => {
  if (data) {
    const updatedFormData = { ...formData };
    updatedFormData.bgDotColor = []
    updatedFormData.differentDotSizes = []

    if (data["background_color"]) {
      const bgColorModel = getColorModel(data["background_color"]);
      if (bgColorModel === "Lab") {
        updatedFormData.bgColorCielab = parseLabColor(data["background_color"]);
        updatedFormData.bgColorRgb = { R: '', G: '', B: '' };
        updatedFormData.bgColorCmyk = { C: '', M: '', Y: '', K: '' };
      } else if (bgColorModel === "RGB") {
        updatedFormData.bgColorRgb = parseRgbColor(data["background_color"]);
        updatedFormData.bgColorCielab = { L: '', A: '', B: '' };
        updatedFormData.bgColorCmyk = { C: '', M: '', Y: '', K: '' };
      } else if (bgColorModel === "CMYK") {
        updatedFormData.bgColorCmyk = parseCmykColor(data["background_color"]);
        updatedFormData.bgColorCielab = { L: '', A: '', B: '' };
        updatedFormData.bgColorRgb = { R: '', G: '', B: '' };
      }
    }
    if (data["luminescence_dot_color"] && data["luminescence_dot_color"].length > 0) {
      data["luminescence_dot_color"].forEach((item, index) => {
        if (item["color"]) {
          const luminescenceValues = Array.from({ length: 6 }, (_, i) => {
            const value = item["luminescence"][i] ?? '';
            return String(value);
          });
          const bgDotColorObj = {
            luminescenceValues,
            percentage: item["percentage"]
          };
          const bgColorModel = getColorModel(item["color"]);
          if (bgColorModel === "Lab") {
            bgDotColorObj.dotColorCielab = parseLabColor(item["color"]);
            bgDotColorObj.dotColorRgb = { R: '', G: '', B: '' };
            bgDotColorObj.dotColorCmyk = { C: '', M: '', Y: '', K: '' };
          } else if (bgColorModel === "RGB") {
            bgDotColorObj.dotColorRgb = parseRgbColor(item["color"]);
            bgDotColorObj.dotColorCielab = { L: '', A: '', B: '' };
            bgDotColorObj.dotColorCmyk = { C: '', M: '', Y: '', K: '' };
          } else if (bgColorModel === "CMYK") {
            bgDotColorObj.dotColorCmyk = parseCmykColor(item["color"]);
            bgDotColorObj.dotColorCielab = { L: '', A: '', B: '' };
            bgDotColorObj.dotColorRgb = { R: '', G: '', B: '' };
          }
          updatedFormData.bgDotColor.push(bgDotColorObj);
        }
      });
    }
    if (data["Dotselection Type"]) {
      updatedFormData.dotSelectionType = data["Dotselection Type"];
    }
    if (data["min"]) {
      updatedFormData.differentDotSizeMin = data["min"];
    }
    if (data["max"]) {
      updatedFormData.differentDotSizeMax = data["max"];
    }
    if (data["add_more"]) {
      updatedFormData.differentDotSizes.push(...data["add_more"]);
    }
    if (data["min_distance"]) {
      updatedFormData.distanceBetweenDotsMin = data["min_distance"];
    }
    if (data["max_distance"]) {
      updatedFormData.distanceBetweenDotsMax = data["max_distance"];
    }
    if (data["shape"]) {
      updatedFormData.frameShape = data["shape"];
    }
    if (data["diameter"]) {
      updatedFormData.shapeSizeDiameterOrHeight = data["diameter"];
    }
    if (data["height"]) {
      updatedFormData.shapeSizeDiameterOrHeight = data["height"];
    }
    if (data["width"]) {
      updatedFormData.shapeSizeWidth = data["width"];
    }
    if (data["inputNumber"]) {
      updatedFormData.numberToDraw = data["inputNumber"];
    }
    if (data["font"]) {
      setSelectedFont({ value: data["font"], label: data["font"] });
    }
    if (data["activeColor"]) {
      const luminescenceValues = Array.from({ length: 6 }, (_, i) => {
        const value = data["activeColor"]?.luminescence_values[i] ?? '';
        return String(value);
      });
      setInputColor(rgbToHex(data["activeColor"]?.color?.rgb !== '' ? data["activeColor"]?.color?.rgb : 'rgb(255,255,255'))
      setSelectedColor({ color: { lab: inputStringToArray(data["activeColor"]?.color?.lab !== '' ? data["activeColor"]?.color?.lab : 'Lab(100,0,0)'), rgb: inputStringToArray(data["activeColor"]?.color?.rgb !== '' ? data["activeColor"]?.color?.rgb : 'rgb(255,255,255)'), cmyk: inputStringToArray(data["activeColor"]?.color?.cmyk !== '' ? data["activeColor"]?.color?.cmyk : 'cmyk(0,0,0,0)') } });
      setInputColorLuminescenceValues(luminescenceValues);
    }
    setFormData(updatedFormData);
  }
};

