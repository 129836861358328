/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import convert from 'color-convert';
import { handleUserImageChange } from '../../commonfunctions/UserUpload';
import EyeDropper from '../colorpicker/EyeDropper';
import { HiOutlinePlus, HiOutlineMinus } from "react-icons/hi2";
import { FcAddImage } from "react-icons/fc";
import { bookmarkColor } from '../../auth/Authcrud';
import ColorThief from 'colorthief';
import { rgbToHex } from '../../commonfunctions/ImageEditorFunctions';

const ColorPicker = ({ isBookmarked, setIsBookmarked }) => {
  const [selectedUserImage, setSelectedUserImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState({ rgb: '', cmyk: '' });
  const [colorPalette, setColorPalette] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const colorsPerPage = 12;
  const canvasRef = React.useRef();
  const contextRef = React.useRef();
  const [bookmarkColorsFromImage, setBookmarkColorsFromImage] = useState([]);
  const [hoveredColors, setHoveredColors] = useState(null);
  const [eyedropperPosition, setEyedropperPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const image = new Image();
    image.src = selectedUserImage;
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const screenWidth = window.innerWidth;
      let canvasWidth, canvasHeight;
      if (screenWidth < 600) {
        // Small screens
        canvasWidth = 400;
        canvasHeight = 300;
      } else {
        // Large screens
        canvasWidth = 550;
        canvasHeight = 400;
      }
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      contextRef.current = context;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Draw the image onto the canvas
      context.drawImage(image, 0, 0, canvasWidth, canvasHeight);
      // Calculate unique colors based on the new canvas size
      const colorThief = new ColorThief();
      const palette = colorThief.getPalette(image, 20); // You can specify the number of colors to extract

      const colorObjects = palette.map(color => ({
        rgb: `rgb(${color.join(', ')})`,
        hex: rgbToHex(`rgb(${color.join(', ')})`)
      }));
      setColorPalette(colorObjects.slice(startIndex, startIndex + colorsPerPage));
    };

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let canvasWidth, canvasHeight;
      if (screenWidth < 600) {
        // Small screens
        canvasWidth = 400;
        canvasHeight = 300;
      } else {
        // Large screens
        canvasWidth = 550;
        canvasHeight = 400;
      }

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      contextRef.current = context;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Draw the image onto the canvas
      context.drawImage(image, 0, 0, canvasWidth, canvasHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [startIndex, selectedUserImage]);

  useEffect(() => {
    if (startIndex !== 0) {
      setTimeout(() => {
        setStartIndex((prevIndex) => 0);
      }, 100)
    }
  }, [selectedUserImage])

  const handleMouseMove = (e) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    canvas.width = canvasRef.current.width;
    canvas.height = canvasRef.current.height;
    context.drawImage(canvasRef.current, 0, 0, canvas.width, canvas.height);

    const gridSize = 7;
    const colors = [];

    for (let i = -gridSize / 2; i <= gridSize / 2; i++) {
      for (let j = -gridSize / 2; j <= gridSize / 2; j++) {
        const x = offsetX + i;
        const y = offsetY + j;
        const pixel = context.getImageData(x, y, 1, 1).data;
        const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
        colors.push(color);
      }
    }
    setHoveredColors(colors);
    setEyedropperPosition({ x: offsetX, y: offsetY });
  };

  const handleMouseLeave = () => {
    setHoveredColors(null);
  };

  const handleImageClick = (event) => {
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;

    const context = contextRef.current;
    const imageData = context.getImageData(x, y, 1, 1).data;
    const rgb = {
      r: imageData[0],
      g: imageData[1],
      b: imageData[2],
    };
    // Convert RGB to CMYK
    const cmyk = convert.rgb.cmyk(rgb.r, rgb.g, rgb.b);

    setSelectedColor({
      rgb: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`,
      cmyk: `cmyk(${cmyk[0]}%, ${cmyk[1]}%, ${cmyk[2]}%, ${cmyk[3]}%)`
    });
  };

  const handleImageDoubleClick = (event) => {
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;

    const context = contextRef.current;
    const imageData = context.getImageData(x, y, 1, 1).data;
    const rgb = {
      r: imageData[0],
      g: imageData[1],
      b: imageData[2],
    };
    // Convert RGB to CMYK
    const cmyk = convert.rgb.cmyk(rgb.r, rgb.g, rgb.b);

    setSelectedColor({
      rgb: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`,
      cmyk: `cmyk(${cmyk[0]}%, ${cmyk[1]}%, ${cmyk[2]}%, ${cmyk[3]}%)`
    });

    const authToken = localStorage.getItem('token')
      const payload = {
        rgb: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`,
        cmyk: `cmyk(${cmyk[0]}%, ${cmyk[1]}%, ${cmyk[2]}%, ${cmyk[3]}%)`
      }
      bookmarkColor(payload, authToken).then(res => {
        setBookmarkColorsFromImage([...bookmarkColorsFromImage, payload]);
        setIsBookmarked(!isBookmarked);
      }).catch(err => {
        console.log(err)
      })
  };

  const handlePaletteClick = (color) => {
    // Convert RGB to CMYK
    const rgbValues = color.rgb
      .match(/\d+/g)
      .map((value) => parseInt(value, 10));
    // Create an object with r, g, b properties
    const rgbObject = {
      r: rgbValues[0],
      g: rgbValues[1],
      b: rgbValues[2],
    };
    const cmyk = convert.rgb.cmyk(rgbObject.r, rgbObject.g, rgbObject.b);
    setSelectedColor({
      rgb: color.rgb,
      cmyk: `cmyk(${cmyk[0]}%, ${cmyk[1]}%, ${cmyk[2]}%, ${cmyk[3]}%)`, // Update the CMYK value directly
    });
  };

  const handlePaletteDoubleClick = (color) => {
    // Convert RGB to CMYK
    const rgbValues = color.rgb
      .match(/\d+/g)
      .map((value) => parseInt(value, 10));
    // Create an object with r, g, b properties
    const rgbObject = {
      r: rgbValues[0],
      g: rgbValues[1],
      b: rgbValues[2],
    };
    const cmyk = convert.rgb.cmyk(rgbObject.r, rgbObject.g, rgbObject.b);
    setSelectedColor({
      rgb: color.rgb,
      cmyk: `cmyk(${cmyk[0]}%, ${cmyk[1]}%, ${cmyk[2]}%, ${cmyk[3]}%)`, // Update the CMYK value directly
    });

    const authToken = localStorage.getItem('token')
      const payload = {
        rgb: color.rgb,
        cmyk: `cmyk(${cmyk[0]}%, ${cmyk[1]}%, ${cmyk[2]}%, ${cmyk[3]}%)`
      }
      bookmarkColor(payload, authToken).then(res => {
        setBookmarkColorsFromImage([...bookmarkColorsFromImage, payload]);
        setIsBookmarked(!isBookmarked);
      }).catch(err => {
        console.log(err)
      })
  };

  const handleNextClick = () => {
    setStartIndex(startIndex + colorsPerPage);
  };

  const handlePrevClick = () => {
    setStartIndex(Math.max(0, startIndex - colorsPerPage));
  };

  return (
    <div className="p-8 lg:p-0 lg:flex h-[600px] lg:h-full overflow-y-scroll lg:overflow-hidden">
      <div className="w-[350px] h-[300px] lg:w-[700px] lg:h-[600px] bg-gray-200 relative">
        {/* Left Section (60% width, gray background) */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          {selectedUserImage == null &&
            <label className="p-2 bg-gray-100 text-sm cursor-pointer flex items-center justify-center space-x-2 rounded-md transition duration-300">
              <FcAddImage className="text-xl" /> {/* Cloud upload icon */}
              <span className="font-normal text-xs">Select Image</span>
              <input
                type="file"
                className="hidden"
                accept="image/*,.svg"
                onChange={(e) => handleUserImageChange(e, setSelectedUserImage)}
              />
            </label>
          }
          {
            selectedUserImage && <div>
              <div className="relative w-[400px] h-[300px] ml-[15px] mt-[90px] lg:ml-0 lg:mt-0 lg:w-[550px] lg:h-[400px]">
                <canvas
                  ref={canvasRef}
                  onClick={handleImageClick}
                  onDoubleClick={handleImageDoubleClick}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  className="cursor-crosshair w-full h-full mt-[90px] lg:ml-0 lg:mt-0 lg:w-[550px] lg:h-[400px]"
                />
                {hoveredColors && <EyeDropper colors={hoveredColors} position={eyedropperPosition} />}
              </div>
              <div className='w-[400px] lg:w-[550px] h-[70px] mt-5 ml-2 lg:ml-0 border border-1 border-gray-300'>
                <div className="flex flex-wrap mt-2 ml-2">
                  <div className="mr-2">
                    <button className="block w-9 h-6 border border-gray-300 cursor-pointer rounded hover:border-gray-700" onClick={handleNextClick}>
                      <HiOutlinePlus className='w-3 h-3 ml-[11px] text-gray-700' />
                    </button>
                    <button className="block w-9 h-6 border border-gray-300 cursor-pointer rounded hover:border-gray-700" onClick={handlePrevClick}>
                      <HiOutlineMinus className='w-3 h-3 ml-[11px] text-gray-700' />
                    </button>
                  </div>
                  {colorPalette.map((color, index) => (
                    <div
                      key={index}
                      onClick={() => handlePaletteClick(color)}
                      onDoubleClick={() => handlePaletteDoubleClick(color)}
                      className="w-[27px] lg:w-[40px] h-12 cursor-pointer "
                      style={{ backgroundColor: color.hex }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="w-[350px] h-[250px] lg:w-[450px] lg:h-[600px] bg-white">
        {/* Right Section (40% width, white background) */}
        <div className='mt-28 lg:mt-12 ml-8 mr-8 '>
          <div className='flex'>
            <div
              className="w-8 h-8 rounded-full bg-white border border-1 border-gray-500"
              style={{ backgroundColor: selectedColor.rgb }}
            ></div>
            {/* RGB input field */}
            <div className="mb-2">
              <input
                type="text"
                className="w-[220px] lg:w-[300px] h-[30px] text-xs px-3 py-2 ml-2 border rounded-md"
                id="loadColorRgbInput"
                placeholder='RGB (digital)'
                value={selectedColor.rgb}
              />
            </div>
          </div>
          <div className='flex'>
            <div
              className="w-8 h-8 rounded-full bg-white border border-1 border-gray-500"
              style={{ backgroundColor: selectedColor.rgb }}
            ></div>
            {/* CMYK input field */}
            <div className="mb-2">
              <input
                type="text"
                className="w-[220px] lg:w-[300px] h-[30px] text-xs px-3 py-2 ml-2 border rounded-md"
                id="loadColorCmykInput"
                placeholder='CMYK (print)'
                value={selectedColor.cmyk}
              />
            </div>
          </div>
        </div>
        <div className="border-b-[1px] border-gray-300 mt-2 mb-2"></div>
        <div className='ml-8 mt-4'>
          <div className='text-xs text-gray-700 mt-2 mb-1'>BOOKMARK COLORS</div>
          {/* Container for bookmark Colors circles */}
          <div className="flex space-x-2 lg:space-x-4 mt-2">
            {bookmarkColorsFromImage.length > 0 && bookmarkColorsFromImage.slice(-8).map((color, index) => (
              <div
                key={index}
                className="w-8 h-8 rounded-full bg-white border border-1 border-gray-500"
                style={{ backgroundColor: color.rgb }}
              ></div>
            ))}
          </div>
        </div>
        {selectedUserImage !== null &&
          <label className="p-2 bg-gray-100 text-sm cursor-pointer flex items-center justify-center space-x-2 rounded-md transition duration-300 absolute bottom-6 right-5">
            <FcAddImage className="text-xl" /> {/* Cloud upload icon */}
            <span className="font-normal text-xs">Select New Image</span>
            <input
              type="file"
              className="hidden"
              accept="image/*,.svg"
              onChange={(e) => handleUserImageChange(e, setSelectedUserImage)}
            />
          </label>
        }
      </div>
    </div>
  );
};
export default ColorPicker;