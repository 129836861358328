import React, { useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';

const ConfirmPopup = ({isOpen,onClose,title, description, onConfirm, onCancel,setRename,popupError, setPopupError, display, displayDescription,type}) => {
    if (!isOpen) return null;
    const handleRenameChange = (e) => {
        const newValue = e.target.value;
        if (newValue.includes(' ')) {
          setPopupError('Spaces are not allowed.');
        }  else {
          setRename(newValue);
          setPopupError('');
        }
      };
      
    return (
        <div className='absolute top-0 w-screen h-screen flex justify-center items-center z-[99]' style={{backgroundColor: "rgba(0,0,0,0.3)"}}>
            <div className='bg-white text-black border p-1 rounded w-[500px] box-shadow-sm'>
                <div className='flex justify-end'>
                    <button className='p-1 rounded-full bg-slate-300 text-black' onClick={onClose}><IoMdClose /></button>
                </div>
                <div className='px-24 text-center'>
                <div className='text-xl p-3'><b>{title} {description}</b></div>
                {/* <p className={displayDescription === "block" ? `block mt-[-5px] text-xl`: `hidden`}><b>{description}</b></p> */}
                </div>
                    <div className='flex justify-center'>
                <input type="text" placeholder='Please enter the text' className={display === "hidden" ? `hidden`: `block w-80 p-2 border mx-5 my-1`}
                onChange={(e)=>handleRenameChange(e)}
                />
                </div>
                {type === 'rename' && popupError !== '' && <div className='flex justify-center mt-[-2px] text-xs text-red-600'>
                                                        {popupError}*
                                                    </div>}
                <div className='flex justify-center pt-5 pb-2 mt-[-15px]'>
                    <button onClick={onCancel} className='px-2 py-1 border mr-8 rounded text-sky-700'>No</button>
                    <button onClick={onConfirm} className='px-2 py-1 text-white rounded bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B]'>Yes</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPopup;
