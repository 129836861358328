import React from 'react';
import { RiErrorWarningLine, RiCheckLine } from 'react-icons/ri';

const Tooltip = ({position, content, children}) => {
  return (
    <div id="tooltip" className='relative cursor-pointer group w-full'>
      <div>{children}</div>
      <span 
      className={position === "top" ? `mb-1 left-1/2 -translate-x-1/2 bottom-[calc(100%-5px)] absolute ${content.message !== '' ? 'inline-block' : 'hidden'} ${content.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white text-xs p-2 whitespace-nowrap rounded-md`:""
      }>
      <div className='flex'>{content.type === 'success' ? <RiCheckLine className="mr-2 mt-[2px]" /> : <RiErrorWarningLine className="mr-2 mt-[2px]" />}{content.message}</div>
      </span>
      <span className={position === "top" ? `mb-2 left-1/2 -translate-x-1/2 bottom-[75%] absolute ${content.message !== '' ? 'inline-block' : 'hidden'} border-[5px] border-l-transparent border-r-transparent border-b-0  ${content.type === 'success' ? 'border-t-green-500' : 'border-t-red-500'}`:"absolute inner-block border-[5px]"}></span>
      
    </div>
  );
}

export default Tooltip;
