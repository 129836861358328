import React from 'react';
import * as FaIcon from "react-icons/fa6";
import * as FaIcons from 'react-icons/fa6';
import * as TiIcons from 'react-icons/ti';
import { MdDragIndicator } from "react-icons/md";
import { MdDelete } from "react-icons/md";
const RotateButton = ({isDragging}) => {
  return (
    <div className="fixed top-20 bg-gray-100 text-xl z-50 flex">
      <button type="button" id='rotate'
        className="block p-1.5 text-gray-500 pr-2 border-gray-300 hover:bg-gray-600 hover:text-white border-e-[1px]"
      ><FaIcon.FaArrowRotateRight /></button>
      <button type="button" id='drag'
        className={`block ${isDragging ? 'bg-blue-400 text-gray-100 ' : 'bg-gray-100 text-gray-500'} text-md p-1.5 hover:bg-gray-600 hover:text-white w-full  border-gray-300 border-e-[1px]`}
      ><MdDragIndicator /></button>
      <button type="button" id='zoomintextelement'
        className="block bg-gray-100 text-md  p-1.5 text-gray-500 hover:bg-gray-600 hover:text-white  border-gray-300 border-e-[1px]"
      ><FaIcons.FaPlus /></button>
      <button type="button" id='zoomouttextelement'
        className="block bg-gray-100 text-md p-1.5 text-gray-500 hover:bg-gray-600 hover:text-white w-full  border-gray-300 border-e-[1px]"
      ><TiIcons.TiMinus /></button>
      <button type="button" id='removeborder'
        className="block bg-gray-100 text-md p-1.5 text-gray-500 hover:bg-gray-600 hover:text-white w-full"
      ><MdDelete /></button>
    </div>
  );
}

export default RotateButton;
