import React from 'react';
import * as BiIcon from "react-icons/bi";

const ZmButton = () => {
  return (
    <div className="fixed top-20 bg-gray-100 text-xl z-50 ZMbutton">
    <button type="button" id='zoomin'
        className="block p-1.5 text-gray-500 border-b-[1px] pr-2 border-gray-300 hover:bg-gray-600 hover:text-white"
    ><BiIcon.BiZoomIn /></button>
    <button type="button" id='zoomout'
        className="block p-1.5 text-gray-500 hover:bg-gray-600 hover:text-white w-full"
    ><BiIcon.BiZoomOut /></button>
    </div>
  );
}

export default ZmButton;
