export const determineBackgroundColor = (formData) => {
    const { bgColorRgb, bgColorCielab, bgColorCmyk } = formData;
    if (bgColorRgb.R !== '') return `rgb(${bgColorRgb.R},${bgColorRgb.G},${bgColorRgb.B})`;
    if (bgColorCielab.L !== '') return `Lab(${bgColorCielab.L},${bgColorCielab.A},${bgColorCielab.B})`;
    if (bgColorCmyk.C !== '') return `cmyk(${bgColorCmyk.C}%,${bgColorCmyk.M}%,${bgColorCmyk.Y}%,${bgColorCmyk.K}%)`;
    return '';
};

export const determineDimensionProperty = (formData) => {
    if (formData.frameShape === 'circle') {
        return { diameter: parseInt(formData.shapeSizeDiameterOrHeight) };
    } else if (formData.frameShape === 'rectangle') {
        return { height: parseInt(formData.shapeSizeDiameterOrHeight), width: parseInt(formData.shapeSizeWidth) };
    }
    return {};
};

export const generateLuminescenceDotColorArray = (formData) => {
    if (formData.bgDotColor.length === 1) {
        const defaultPercentage = 100;
        const section = formData.bgDotColor[0];
        const dotColor = determineDotColor(section);
        const luminescenceValuesArray = section.luminescenceValues.map(parseFloat).filter(value => !isNaN(value));
        return [{ index: 0, color: dotColor, luminescence: luminescenceValuesArray, percentage: defaultPercentage }];
    } else {
        return formData.bgDotColor.map((section, index) => {
            const dotColor = determineDotColor(section);
            const luminescenceValuesArray = section.luminescenceValues.map(parseFloat).filter(value => !isNaN(value));
            return { index, color: dotColor, luminescence: luminescenceValuesArray, percentage: parseFloat(section.percentage) };
        });
    }
};

export const determineDotColor = (section) => {
    const { dotColorRgb, dotColorCielab, dotColorCmyk } = section;
    if (dotColorRgb.R !== '') return `rgb(${dotColorRgb.R},${dotColorRgb.G},${dotColorRgb.B})`;
    if (dotColorCielab.L !== '') return `Lab(${dotColorCielab.L},${dotColorCielab.A},${dotColorCielab.B})`;
    if (dotColorCmyk.C !== '') return `cmyk(${dotColorCmyk.C}%,${dotColorCmyk.M}%,${dotColorCmyk.Y}%,${dotColorCmyk.K}%)`;
    return '';
};

export const getEditFormPayload = (formData,backgroundColor,dimensionProperty,luminescenceDotColorArray,editImageId,imageToShow) => {
    return {
        shape: formData.frameShape,
        background_color: backgroundColor,
        ...dimensionProperty,
        min: formData.dotSelectionType === 'dotrange' ? parseFloat(formData.differentDotSizeMin) : null,
        max: formData.dotSelectionType === 'dotrange' ? parseFloat(formData.differentDotSizeMax) : null,
        add_more: formData.dotSelectionType === 'dotsizes' ? formData.differentDotSizes.map(parseFloat).filter(value => !isNaN(value)) : null,
        min_distance: parseFloat(formData.distanceBetweenDotsMin),
        max_distance: parseFloat(formData.distanceBetweenDotsMax),
        luminescence_dot_color: luminescenceDotColorArray,
        id: editImageId,
        name: (imageToShow?.split("/")[imageToShow?.split("/").length - 1]?.split(".")[0]) ?? ''
      };
}

export const isBgDotColorAtIndexEmpty = (dotColor) => {
    // Check if any field in dotColor object is not empty
    if (
      dotColor.dotColorCielab.L !== '' ||
      dotColor.dotColorCielab.A !== '' ||
      dotColor.dotColorCielab.B !== '' ||
      dotColor.dotColorRgb.R !== '' ||
      dotColor.dotColorRgb.G !== '' ||
      dotColor.dotColorRgb.B !== '' ||
      dotColor.dotColorCmyk.C !== '' ||
      dotColor.dotColorCmyk.M !== '' ||
      dotColor.dotColorCmyk.Y !== '' ||
      dotColor.dotColorCmyk.K !== '' ||
      dotColor.luminescenceValues.some(value => value !== '') ||
      dotColor.percentage !== ''
    ) {
      return false; // Return false if any field is not empty
    }
    
    return true; // Return true if all fields are empty
  };