import React from 'react';

const EyeDropper = ({ colors, position }) => (
  <div
    className="eyedropper-container"
    style={{
      position: 'absolute',
      top: position.y - 20,
      left: position.x + 20,
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gridTemplateRows: 'repeat(7, 1fr)',
      border: '1px solid black',
      overflow: 'hidden',
    }}
  >
    {colors.map((color, index) => (
      <div
        key={index}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: color,
          borderRight:  index === 24 ? '1px solid #FF0000' : (index % 7 !== 6 && index !== 23)  ? '1px solid #808080' : 'none',
          borderBottom: index === 24 ? '1px solid #FF0000' : (index < 42 && index !== 17) ? '1px solid #808080' : 'none',
          borderTop: index === 24 ? '1px solid #FF0000' : 'none',
          borderLeft: index === 24 ? '1px solid #FF0000' : 'none',
          boxSizing: 'border-box',
        }}
      />
    ))}
  </div>
);

export default EyeDropper;



       