import React, { useEffect, useState } from 'react';
import loginBackground from '../../media/login-bg.png'
import logoImage from '../../media/logo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword, verifyEmailToken } from '../../auth/Authcrud';
import Tooltip from '../commonUI/Tooltip';
import { IoMdArrowBack } from "react-icons/io";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ForgetPassword = () => {
    const [user, setUser] = useState({});
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [resetTooltip, setResetTooltip] = useState({ message: '', type: '' });
    const [showLogin, setShowLogin] = useState(false);
    const navigate = useNavigate()
    const location = useLocation();
    const currentUrl = location.pathname;
    const handleReset = () => {
        if (password.trim().length === 0 || confirmPassword.trim().length === 0) {
            setResetTooltip({ message: `Please enter a Password.`, type: 'error' });
            setTimeout(() => {
                setResetTooltip({ message: '', type: '' });
            }, 3000);
            return
        }
        if (password === confirmPassword) {
            let payload = {
                email: user.email,
                password1: password,
                password2: confirmPassword
            }
            resetPassword(payload).then(res => {
                if (res.message === 'Your password has been changed successfully') {
                    setResetTooltip({ message: 'Your password has been changed successfully.', type: 'success' });
                    setShowLogin(true)
                } else {
                    setResetTooltip({ message: `${res.message}. Please try again later.`, type: 'error' });
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setTimeout(() => {
                    setResetTooltip({ message: '', type: '' });
                }, 3000);
            });
        } else {
            setResetTooltip({ message: `Password and confirm password must be same.`, type: 'error' });
            setTimeout(() => {
                setResetTooltip({ message: '', type: '' });
            }, 3000);
            return
        }
    }

    useEffect(() => {
        verifyEmailToken(currentUrl).then(res => {
            if (res.message === 'Invalid or expired session') {
                navigate('/forget_password_session_expired')
            } else {
                setUser(res)
            }
        }).catch(err => {
            console.log(err)
            navigate('/forget_password_session_expired')
        })
    }, [])

    return (
        <div className="min-h-screen flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${loginBackground})` }}>
            <div className="bg-white p-8 rounded-xl shadow-md md:w-[400px] sm:w-[350px] text-center Login">
                {/* Logo */}
                <div className="m-6 mb-16 logoSpace">
                    <img src={logoImage} alt="Logo" className="mx-auto h-16 LoginLogo" />
                </div>
                <h2 className="text-2xl font-semibold sm:mb-1 mb-4 text-sky-700 font-AmazonEmberBold LoginHeadline" >Reset Password</h2>

                <form className='mt-16 LoginForm'>
                    {/* New Password */}
                    <div className="mb-4 relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="newpassword"
                            name="mewpassword"
                            className="border rounded w-full py-2 px-3 h-[30px] text-xs text-gray-700"
                            placeholder="New Password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* Toggle button to show/hide password */}
                        <button
                            type="button"
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaEye className='text-blue-500' /> : <FaEyeSlash />}
                        </button>
                    </div>
                    {/* Confirm New Password */}
                    <div className="mb-2 relative">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmpassword"
                            name="confirmpassword"
                            className="border rounded w-full py-2 px-3 h-[30px] text-xs text-gray-700 pr-10"
                            placeholder="Confirm New Password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {/* Toggle button to show/hide password */}
                        <button
                            type="button"
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <FaEye className='text-blue-500' /> : <FaEyeSlash />}
                        </button>
                    </div>
                    {/* reset Button */}
                    <Tooltip position="top" content={resetTooltip}>
                        <button
                            type="button"
                            className="w-full h-[50px] mt-4 mb-2 py-2 px-3 rounded focus:outline-none focus:shadow-outline-blue bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-white"
                            onClick={handleReset}
                        >
                            Reset Password
                        </button>
                    </Tooltip>
                    {showLogin && <button
                            type="button"
                            className="w-full h-[50px] mt-2 mb-2 py-2 px-3 rounded focus:outline-none focus:shadow-outline-blue bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-white"
                            onClick={()=>navigate('/',{ state: { existingEmail : user.email  } })}
                        >
                            <div className='flex justify-center items-center'><IoMdArrowBack  className='mr-1'/>Login</div>
                        </button>}
                </form>
            </div>
        </div>
    );
}

export default ForgetPassword;
