import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {

    const error = useRouteError();

    let title = "An error occurred!";
    let message ="Something went wrong!";

    if(error.status === 500){
        message = error.data.message;
    }

    if(error.status === 404){
        title = "working in progress";
        message = "Could not find resource or Page."
    }


  return (
    <div>
    <div>
        <h1>{title}</h1>
        <p>{message}</p>
    </div>
      
    </div>
  );
}

export default ErrorPage;
