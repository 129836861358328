/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkAuthToken, editImage, getBookmarkColorList, imageDetail, saveAsImage, saveEditDotParameter } from '../../auth/Authcrud';
import Loader from '../commonUI/Loader';
import rainbowImage from '../../media/colorpicker.png';
import Modal from '..//commonUI/Modal';
import { handleLabChange, handleRGBChange, handleCmykChange, handleLoadColor } from '../../commonfunctions/ColorPickerFunctions'
import { handleParameterFileChange, handleParameterUpload } from '../../commonfunctions/UserUpload';
import { fetchDataAndResize, handleCircleClick, handleEditParameterData, handleRemoveDotColor, handleResize, rgbToHex, saveSVG } from '../../commonfunctions/ImageEditorFunctions';
import ColorPicker from '../colorpicker/Colorpicker';
import ZmButton from "../commonUI/ZmButton";
import RotateButton from '../commonUI/RotateButton';
import ConfirmPopup from '../commonUI/ConfirmPopup';
import { determineBackgroundColor, determineDimensionProperty, generateLuminescenceDotColorArray, getEditFormPayload } from '../../commonfunctions/FormPayloadFunctions';
import { isObjectEmpty, validateGenerateImageForm } from '../../commonfunctions/Validations';
import Tooltip from '../commonUI/Tooltip';
import ImageForm from './ImageForm';

const ImageEditor = () => {
  const [isChecked, setChecked] = useState(false);
  const [formData, setFormData] = useState({
    bgColorCielab: { L: '', A: '', B: '' },
    bgColorRgb: { R: '', G: '', B: '' },
    bgColorCmyk: { C: '', M: '', Y: '', K: '' },
    bgDotColor: [{ dotColorCielab: { L: '', A: '', B: '' }, dotColorRgb: { R: '', G: '', B: '' }, dotColorCmyk: { C: '', M: '', Y: '', K: '' }, luminescenceValues: ['', '', '', '', '', ''], percentage: '' }],
    dotSelectionType: 'dotrange',
    differentDotSizeMin: '',
    differentDotSizeMax: '',
    differentDotSizes: [''],
    distanceBetweenDotsMin: '',
    distanceBetweenDotsMax: '',
    frameShape: 'circle',
    shapeSizeDiameterOrHeight: '',
    shapeSizeWidth: '',
    lock: isChecked,
    numberToDraw: '',
  });
  const [outputImage, SetOutputImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();
  let { imageUrl, editImageId } = location.state || {};
  const [imageToShow, SetImageToShow] = useState(imageUrl)
  const svgRef = useRef();
  const [isUploadParameterModalOpen, setIsUploadParameterModalOpen] = useState(false);
  const [selectedParameterFile, setSelectedParameterFile] = useState(null);
  const [isUploadUserImageModalOpen, setIsUploadUserImageModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkColors, setBookmarkColors] = useState([]);
  const [recentlyUsedColors, setRecentlyUsedColors] = useState([]);
  const [formPayload, setFormPayload] = useState();
  const [saveDotParameterTooltip, setSaveDotParameterTooltip] = useState({ message: '', type: '' });
  const [isDragging, setIsDragging] = useState(false);
  const [formError, setFormError] = useState();
  const [parameterUploadError, setParameterUploadError] = useState('');
  const [showError, setShowError] = useState(false);
  const [bgColorNoticeMessage, setBgColorNoticeMessage] = useState('');
  const [dotColorNoticeMessages, setDotColorNoticeMessages] = useState([]);
  const [svgSize, setSvgSize] = useState({ width: 0, height: 0 });
  const [selectedFont, setSelectedFont] = useState({ value: 'AmazonEmberItalic', label: 'AmazonEmberItalic' });
  const [sectionIndexToRemove, setSectionIndexToRemove] = useState(null);

  const openUploadParameterModal = () => {
    setIsUploadParameterModalOpen(true);
  };

  const closeUploadParameterModal = () => {
    setIsUploadParameterModalOpen(false);
    setParameterUploadError('')
  };

  const openUploadUserImageModal = () => {
    setIsUploadUserImageModalOpen(true);
  };

  const closeUploadUserImageModal = () => {
    setIsUploadUserImageModalOpen(false);
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirm = () => {
    handleRemoveDotColor(formData, setFormData, sectionIndexToRemove)
    setIsConfirmModalOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    setShowError(true);

    if (!isObjectEmpty(formError)) {
      setLoading(false);
      return;
    }

    const authToken = localStorage.getItem('token');
    setLoading(true);

    const backgroundColor = determineBackgroundColor(formData);
    const dimensionProperty = determineDimensionProperty(formData);
    const luminescenceDotColorArray = generateLuminescenceDotColorArray(formData);
    const payload = getEditFormPayload(formData, backgroundColor, dimensionProperty, luminescenceDotColorArray, editImageId, imageToShow)
    setFormPayload(payload);
    editImage(payload, authToken)
      .then((res) => {
        SetOutputImage(res.image)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleEdit = () => {
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    imageDetail(editImageId, authToken).then(res => {
      handleEditParameterData(res.data['form_values'][0], formData, setFormData, setSelectedFont,setInputColor,setSelectedColor,setInputColorLuminescenceValues)
    }).catch(err => {
      console.log(err)
    })
  };

  useEffect(() => {
    SetImageToShow(outputImage ? outputImage : imageUrl);
  }, [imageUrl, outputImage])

  useEffect(() => {
    const backgroundColor = determineBackgroundColor(formData);
    const dimensionProperty = determineDimensionProperty(formData);
    const luminescenceDotColorArray = generateLuminescenceDotColorArray(formData);
    const payload = getEditFormPayload(formData, backgroundColor, dimensionProperty, luminescenceDotColorArray, editImageId, imageToShow)
    setFormPayload(payload);
  }, [formData])

  useEffect(() => {
    let allValidations = {};
    if (formData) {
      const { bgColorCielab, bgColorRgb, bgColorCmyk } = formData;
      const filledBgColorCount = [bgColorCielab, bgColorRgb, bgColorCmyk].reduce((count, item) => {
        if (Object.values(item).some(value => value !== '')) {
          return count + 1;
        }
        return count;
      }, 0);
      if (filledBgColorCount === 2) {
        setBgColorNoticeMessage('RGB has the highest priority, followed by CIELAB, and then CMYK.');
      } else {
        setBgColorNoticeMessage('');
      }
      const { bgDotColor } = formData;
      const dotMessages = bgDotColor.map(dot => {
        const { dotColorCielab, dotColorRgb, dotColorCmyk } = dot;
        const filledCount = [dotColorCielab, dotColorRgb, dotColorCmyk].reduce((count, item) => {
          if (Object.values(item).some(value => value !== '')) {
            return count + 1;
          }
          return count;
        }, 0);
        if (filledCount === 2) {
          return 'RGB has the highest priority, followed by CIELAB, and then CMYK.';
        } else {
          return '';
        }
      });
      setDotColorNoticeMessages(dotMessages);
      validateGenerateImageForm(formData, formData.dotSelectionType, allValidations, setFormError);
    }
  }, [formData, formData.dotSelectionType]);

  const handleSaveAsImage = () => {
    const svgContainer = document.getElementById('svgContainer');
    const filename = 'modified_svg.svg';
    // Get the SVG content from the container
    const svgContent = svgContainer.innerHTML;
    // Save the SVG file with the specified width and height
    let svgBlob = saveSVG(svgContent, filename, svgSize.width, svgSize.height, 'save_as_image');
    // Get the existing image name
    const parts = imageToShow?.split("/");
    const filenameWithExtension = parts[parts.length - 1];
    const filenameWithoutExtension = filenameWithExtension.split(".")[0];
    // Create FormData object
    const formData = new FormData();
    formData.append('new_image', svgBlob, filename || 'modified_svg.svg');
    formData.append('existing_image', filenameWithoutExtension);
    const authToken = localStorage.getItem('token');
    saveAsImage(formData, authToken)
      .then((res) => {
        SetOutputImage(res.url)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSaveDotParameter = () => {
    const svgContainer = document.getElementById('svgContainer');
    const filename = 'modified_svg.svg';
    // Get the SVG content from the container
    const svgContent = svgContainer.innerHTML;
    // Save the SVG file with the specified width and height
    let svgBlob = saveSVG(svgContent, filename, svgSize.width, svgSize.height, 'save_dot_parameter');
    // Get the existing image name
    const parts = imageToShow?.split("/");
    const filenameWithExtension = parts[parts.length - 1];
    const filenameWithoutExtension = filenameWithExtension.split(".")[0];
    // Create FormData object
    const formdata = new FormData();
    formdata.append('new_image', svgBlob, filename || 'modified_svg.svg');
    formdata.append('existing_image', filenameWithoutExtension);
    const authToken = localStorage.getItem('token');
    if (!checkAuthToken(authToken)) return;
    saveAsImage(formdata, authToken)
      .then((res) => {
        SetOutputImage(res.url)
        const activeColorLuminescenceValuesArray = inputColorLuminescenceValues.map(parseFloat).filter(value => !isNaN(value));
        const payload = {
          id: editImageId,
          url: res.url,
          form_values: [{
            ...formPayload, inputNumber: formData.numberToDraw, font: selectedFont.value,
            activeColor: {
              color: {
                lab: selectedColor.color.lab !== '' ? `Lab(${selectedColor.color.lab[0]}, ${selectedColor.color.lab[1]}, ${selectedColor.color.lab[2]})` : '',
                rgb: selectedColor.color.rgb !== '' ? `rgb(${selectedColor.color.rgb[0]}, ${selectedColor.color.rgb[1]}, ${selectedColor.color.rgb[2]})` : '',
                cmyk: selectedColor.color.cmyk !== '' ? `cmyk(${selectedColor.color.cmyk[0]}, ${selectedColor.color.cmyk[1]}, ${selectedColor.color.cmyk[2]}, ${selectedColor.color.cmyk[3]})` : ''
              },
              luminescence_values: activeColorLuminescenceValuesArray
            }
          }]
        };
        saveEditDotParameter(payload, authToken).then(res => {
          setSaveDotParameterTooltip({ message: 'Save Dot Parameter saved successfully.', type: 'success' });
        }).catch(err => {
          setSaveDotParameterTooltip({ message: 'Save failed. Please try again later.', type: 'error' });
          console.log(err)
        }).finally(() => {
          setTimeout(() => {
            setSaveDotParameterTooltip({ message: '', type: '' });
          }, 3000);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inputRef = useRef();
  const [inputColor, setInputColor] = useState('');
  const [selectedColor, setSelectedColor] = useState({ color: { rgb: '', lab: '', cmyk: '' } });
  const [inputColorLuminescenceValues, setInputColorLuminescenceValues] = useState(['', '', '', '', '', '']);
  const [shades, setShades] = useState([]);
  const inputColorRef = useRef(inputColor)
  const inputColorLuminescenceValuesRef = useRef(inputColorLuminescenceValues)
  const shadesRef = useRef(shades)
  const [loadColorError, setLoadColorError] = useState('');

  const handleColorChange = (e) => {
    setInputColor(e.target.value);
  };

  const handleInputColorLuminescenceChange = (valueIndex, luminescenceValue) => {
    setInputColorLuminescenceValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[valueIndex] = luminescenceValue;
      return updatedValues;
    });
  };

  useEffect(() => {
    handleEdit();
    const input = inputRef.current;
    if (input) {
      input.addEventListener('input', handleColorChange);
      return () => {
        input.removeEventListener('input', handleColorChange);
      };
    }
  }, []);

  const handleBookmarkOrRecentColorClick = (color) => {
    setInputColor(rgbToHex(color));
    handleLoadColor(rgbToHex(color), selectedColor, setSelectedColor, inputColorLuminescenceValues, setShades, shadesRef)
  };

  useEffect(() => {
    const authToken = localStorage.getItem('token')
    if (!checkAuthToken(authToken)) return;
    getBookmarkColorList(authToken).then(res => {
      setBookmarkColors(res.colors)
    }).catch(err => {
      console.log(err)
    })
  }, [isBookmarked])

  useEffect(() => {
    inputColorRef.current = inputColor;
    inputColorLuminescenceValuesRef.current = inputColorLuminescenceValues;
    const allEmpty = inputColorLuminescenceValues.every(value => value === '');
    !allEmpty && setLoadColorError('')
  }, [inputColor, inputColorLuminescenceValues]);

  useEffect(() => {
    let textElement
    const svgContainer = document.getElementById('svgContainer');
    fetchDataAndResize(imageToShow, svgContainer, textElement, formData, svgRef, setRecentlyUsedColors, setIsDragging, setSvgSize, selectedFont); // Initial fetch and resize

    let pinchScale = 1;
    let isPinching = false;
    let initialPinchPosition = { x: 0, y: 0 };
    let currentShadeIndex = 0;

    let initialPinchDistance = 0;

    const startPinch = (e) => {
      isPinching = true;
      initialPinchPosition = { x: e.clientX, y: e.clientY };
    };

    const movePinch = (e) => {
      if (isPinching) {
        const currentPinchDistance = Math.hypot(
          e.clientX - initialPinchPosition.x,
          e.clientY - initialPinchPosition.y
        );
        // Move the initialization of initialPinchDistance here
        if (initialPinchDistance === 0) {
          initialPinchDistance = currentPinchDistance;
        }

        const pinchDelta = currentPinchDistance - initialPinchDistance;
        const pinchThreshold = 10;
        if (Math.abs(pinchDelta) > pinchThreshold) {
          // Adjust pinch scale
          pinchScale += pinchDelta > 0 ? 0.1 : -0.1;
          pinchScale = Math.max(0.5, Math.min(2, pinchScale));
          // Cycle through shades
          currentShadeIndex = shadesRef.current.length === 0 ? 0 : (currentShadeIndex + 1) % shadesRef.current.length;
          // Check if the target element is a circle
          const targetElement = document.elementFromPoint(e.clientX, e.clientY);
          if (targetElement && targetElement.tagName === 'circle') {
            const circle = targetElement;
            // Check if the circle matches the criteria to be prevented from filling
            const cx = circle.getAttribute('cx');
            const cy = circle.getAttribute('cy');
            const r = circle.getAttribute('r');
            if (!(cx === '50%' && cy === '50%' && r === '35%')) {
              // Identify the largest circle and exclude it
              const allCircles = Array.from(svgContainer.querySelectorAll('circle'));
              const largestCircle = allCircles.reduce((largest, current) => {
                const currentRadius = parseFloat(current.getAttribute('r')) || 0;
                const largestRadius = parseFloat(largest.getAttribute('r')) || 0;
                return currentRadius > largestRadius ? current : largest;
              }, allCircles[0]);

              if (circle !== largestCircle) {
                circle.style.fill = shadesRef.current[currentShadeIndex];
              }
            }
          }
          initialPinchPosition = { x: e.clientX, y: e.clientY };
        }
      }
    };

    const endPinch = () => {
      isPinching = false;
      initialPinchDistance = 0;
    };

    // Mouse event listeners
    svgContainer?.addEventListener('mousedown', (e) => {
      startPinch(e);
      initialPinchDistance = 0;
    });
    svgContainer?.addEventListener('mousemove', movePinch);
    svgContainer?.addEventListener('mouseup', endPinch);
    // Event listeners for window resize
    window.addEventListener('resize', handleResize);
    // Event delegation on svgContainer
    svgContainer?.addEventListener('click', (e) => handleCircleClick(e, inputColorRef.current));
    // Clean up event listeners when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      // Remove event listener from svgContainer
      svgContainer?.removeEventListener('click', (e) => handleCircleClick(e, inputColorRef.current));
      //remove Mouse event listeners
      svgContainer?.removeEventListener('mousedown', startPinch);
      svgContainer?.removeEventListener('mousemove', movePinch);
      svgContainer?.removeEventListener('mouseup', endPinch);
    };
  }, [formData.numberToDraw, selectedFont, imageToShow]);

  return (
    <>
      <div className="relative" style={{ height: "100vh", overflow: "hidden" }}>
        <button type="button"
          className="z-10 bottom-[80px] absolute left-4 bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-white text-xs rounded-3xl w-[70px] h-[30px] transition-transform transform hover:scale-105 backBtnMobileEdit"
          onClick={() => navigate('/dashboard')}>Back</button>
        <div className="flex flex-col md:flex-row h-screen">
          <div className=" relative md:flex-1 md:w-7/12 bg-white overflow-auto mainView flex justify-center items-center relative" style={{ width: "100%", height: "calc(100vh - 57px)", overflow: "auto" }}>
            <ZmButton />
            {formData.numberToDraw !== '' && formData.numberToDraw !== null && formData.numberToDraw !== undefined && (
              <RotateButton isDragging={isDragging} />
            )}
            {!loading ? imageToShow !== null ? <div
              className="p-8 w-0 h-0 cursor-pointer flex justify-center items-center absolute top-4 left-2 select-none" id='svgparent'>
              <svg ref={svgRef} width="100%" height="100%" id='svgContainer' xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" />
            </div> : <div></div> : <Loader />}
          </div>
          {/* UploadParameterModal */}
          <Modal isOpen={isUploadParameterModalOpen} onClose={closeUploadParameterModal}>
            <div className='p-8'>
              <h2 className="text-xl font-bold mb-4">Upload Parameter File</h2>
              <input type="file" accept=".csv" onChange={(e) => handleParameterFileChange(e, setSelectedParameterFile)} />
              <button className="mt-4 p-2 bg-gradient-to-b from-[#317BBC] to-[#1D579B] hover:from-[#FF994C] hover:to-[#DB620B] text-white text-xs rounded"
                onClick={() => handleParameterUpload(selectedParameterFile, formData, setFormData, closeUploadParameterModal, setParameterUploadError)}
              >Upload</button>
              {parameterUploadError !== '' && <div className='mt-1 mb-[-10px] text-xs text-red-600'>{`${parameterUploadError}*`}</div>}
            </div>
          </Modal>
          {/* UploadUserImageModal */}
          <Modal isOpen={isUploadUserImageModalOpen} onClose={closeUploadUserImageModal}>
            <ColorPicker isBookmarked={isBookmarked} setIsBookmarked={setIsBookmarked}
              bookmarkColors={bookmarkColors} setBookmarkColors={setBookmarkColors} />
          </Modal>
          <ConfirmPopup
            isOpen={isConfirmModalOpen} onClose={closeConfirmModal}
            title="Are you sure"
            description={`you want to delete color option ${sectionIndexToRemove + 1}?`}
            onConfirm={handleConfirm} onCancel={closeConfirmModal}
            display="hidden"
            displayDescription='block'
            type='delete'
          />
          <div className="parameterBar bg-gray-100">
            <div className="p-4 bg-gray-100 max-w-full sm:max-w-screen-md md:max-w-screen-lg lg:max-w-screen-xl xl:max-w-screen-2xl mx-auto">
              <div className='flex justify-between'>
                <span className='text-xs font-semibold'>PARAMETERS</span>
                <span className='text-xs text-gray-400 cursor-pointer hover:text-blue-500' onClick={openUploadParameterModal}>Upload Parameter File +</span>
              </div>
              <div className="border-b-[1px] border-gray-300 mt-2 mb-1"></div>
              <ImageForm
                formData={formData}
                setFormData={setFormData}
                formError={formError}
                showError={showError}
                bgColorNoticeMessage={bgColorNoticeMessage}
                dotColorNoticeMessages={dotColorNoticeMessages}
                openConfirmModal={openConfirmModal}
                openUploadUserImageModal={openUploadUserImageModal}
                setSectionIndexToRemove={setSectionIndexToRemove}
                selectedFont={selectedFont}
                setSelectedFont={setSelectedFont}
                isChecked={isChecked}
                setChecked={setChecked}
                handleSubmit={handleSubmit}
                loadColorUi={true}
                formButton='EDIT IMAGE'
              />
              <div className="border-b-[1px] border-gray-300 mt-2 mb-3"></div>
              <div className='flex'>
                <div className='w-1/4'>
                  <div className='text-xs text-gray-700 mt-2 ml-1 mb-2'>ACTIVE COLOR</div>
                  <div className='w-16 h-16 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-16 xl:h-16 bg-white border border-1 border-gray-500 rounded-full ml-1 xl:ml-4 2xl:ml-3 mb-2'
                    style={{ backgroundColor: `rgb(${selectedColor.color.rgb !== '' && selectedColor.color.rgb.join(', ')})` }}
                  ></div>
                </div>
                <div className='flex-1'>
                  <div className='flex flex-col lg:flex-row gap-1 mb-1'>
                    {/* CIELAB input field */}
                    <div className="mb-2">
                      <label className="block text-gray-400 text-xs ml-1 mb-1">CIELAB (L40)</label>
                      <input type="text" id="loadColorCielabInput"
                        className="w-full lg:w-22 2xl:w-[120px] h-[30px] text-xs px-3 py-2 border rounded-md"
                        value={selectedColor.color.lab !== '' ? `Lab(${selectedColor.color.lab[0]}, ${selectedColor.color.lab[1]}, ${selectedColor.color.lab[2]})` : ''}
                        onChange={(e) => handleLabChange(e, setInputColor, selectedColor, setSelectedColor)} />
                    </div>
                    {/* RGB input field */}
                    <div className="mb-2">
                      <label className="block text-gray-400 text-xs ml-1 mb-1">RGB (digital)</label>
                      <input type="text" id="loadColorRgbInput"
                        className="w-full lg:w-22 2xl:w-[120px] h-[30px] text-xs px-3 py-2 border rounded-md"
                        value={selectedColor.color.rgb !== '' ? `rgb(${selectedColor.color.rgb[0]}, ${selectedColor.color.rgb[1]}, ${selectedColor.color.rgb[2]})` : ''}
                        onChange={(e) => handleRGBChange(e, setInputColor, selectedColor, setSelectedColor)} />
                    </div>
                    {/* CMYK input field */}
                    <div className="mb-2">
                      <label className="block text-gray-400 text-xs ml-1 mb-1">CMYK (print)</label>
                      <input type="text" id="loadColorCmykInput"
                        className="w-full lg:w-22 2xl:w-[120px] h-[30px] text-xs px-3 py-2 border rounded-md"
                        value={selectedColor.color.cmyk !== '' ? `cmyk(${selectedColor.color.cmyk[0]}, ${selectedColor.color.cmyk[1]}, ${selectedColor.color.cmyk[2]}, ${selectedColor.color.cmyk[3]})` : ''}
                        onChange={(e) => handleCmykChange(e, setInputColor, selectedColor, setSelectedColor)} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-gray-400 text-xs ml-1 mb-1">LUMINESCENCE VALUES</label>
                    <div className="flex gap-1 flex-wrap">
                      {inputColorLuminescenceValues.map((value, index) => (
                        <div className="mb-2">
                          <input
                            type="text"
                            className="w-[60px] md:w-[50px]  h-[30px] text-xs px-3 py-2 border rounded-md"
                            id={`inputColorLuminescenceInput${index}`}
                            value={inputColorLuminescenceValues[index]}
                            onChange={(e) => handleInputColorLuminescenceChange(index, e.target.value)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  {loadColorError !== '' && <div className='mt-[-2px] mb-1 text-xs text-red-600'>
                    {loadColorError}*
                  </div>}
                  <div className='flex gap-4'>
                    <div className="relative inline-block w-10 h-[30px] bg-white rounded-md">
                      <input type="color" value={inputColor} ref={inputRef}
                        className="appearance-none ml-4 w-2 h-2 rounded-md border-none outline-none cursor-pointer"
                        onChange={(e) => {
                          handleColorChange(e)
                          handleLoadColor(e.target.value, selectedColor, setSelectedColor, inputColorLuminescenceValues, setShades, shadesRef)
                        }} />
                      <div
                        className='absolute top-[3px] right-0 bottom-0 left-[5px] rounded-md cursor-pointer bg-cover bg-center w-6 h-6'
                        style={{ backgroundImage: `url(${rainbowImage})` }}
                        onClick={() => inputRef.current.click()}
                      ></div>
                    </div>
                    <button type="button"
                      className="shadow-md w-full h-[30px] bg-blue-100 text-sm xl:text-xs text-gray-700 px-2 py-1 rounded-md hover:bg-blue-300"
                      onClick={() => {
                        handleLoadColor(inputColor, selectedColor, setSelectedColor, inputColorLuminescenceValues, setShades, shadesRef)
                        setLoadColorError('')
                        const allEmpty = inputColorLuminescenceValues.every(value => value === '');
                        if (allEmpty) {
                          setLoadColorError('Please fill in at least one luminescence value.');
                          return;
                        }
                      }}
                    >LOAD COLOR</button>
                  </div>
                </div>
              </div>
              <div className='text-xs text-gray-700 mt-2 ml-1 mb-2'>RECENTLY USED</div>
              {/* Container for recently used Colors circles */}
              <div className="flex space-x-5 md:space-x-1 lg:space-x-2 xl:space-x-4 2xl:space-x-6 RecentScroll">
                {recentlyUsedColors && recentlyUsedColors.length > 0 && recentlyUsedColors.map((color, index) => (
                  <div className='py-2 pe-2'><div key={index}
                    className="w-8 h-8 md:w-6 md:h-6 lg:w-7 lg:h-7 xl:w-8 xl:h-8 rounded-full bg-white border border-1 border-gray-500 cursor-pointer"
                    style={{ backgroundColor: color }}
                    onClick={() => handleBookmarkOrRecentColorClick(color)}
                  ></div></div>
                ))}
              </div>
              <div className='text-xs text-gray-700 mt-2 ml-1 mb-2'>BOOKMARK COLOR</div>
              {/* Container for bookmark Colors circles */}
              <div className="flex space-x-5 md:space-x-1 lg:space-x-2 xl:space-x-4 2xl:space-x-6">
                {bookmarkColors && bookmarkColors.length > 0 && bookmarkColors.slice(-9).map((color, index) => (
                  <div key={index}
                    className="w-8 h-8 md:w-6 md:h-6 lg:w-7 lg:h-7 xl:w-8 xl:h-8 rounded-full bg-white border border-1 border-gray-500 cursor-pointer"
                    style={{ backgroundColor: color.rgb }}
                    onClick={() => handleBookmarkOrRecentColorClick(color.rgb)}
                  ></div>
                ))}
              </div>
              <div className="border-b-[1px] border-gray-300 mt-4 mb-2"></div>
              <div className='flex gap-2'>
                <Tooltip position="top" content={saveDotParameterTooltip}>
                  <button
                    className="relative w-full h-8 md:h-10 lg:h-8 text-sm md:text-xs xl:text-sm text-white px-2 py-1 rounded-md bg-gradient-to-b from-[#4D8EC7] to-[#4D8EC7] hover:from-[#FF994C] hover:to-[#DB620B]"
                    onClick={handleSaveDotParameter}
                  >
                    SAVE DOT PARAMS
                  </button>
                </Tooltip>
                <button type="button"
                  className="w-full h-8 md:h-10 lg:h-8 text-sm md:text-xs xl:text-sm text-gray-700 px-2 py-1 rounded-md bg-gradient-to-b from-[#F8C8AA] to-[#F8C8AA] hover:from-[#FF994C] hover:to-[#DB620B]"
                  onClick={() => { handleSaveAsImage() }}
                >SAVE AS IMAGE (SVG)</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ImageEditor;
